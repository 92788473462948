import { ReactElement } from "react";
import { TwitterIcon, DiscordIcon, MirrorIcon } from "@/assets/svg-iconfont";
interface IconArrInterface {
  icon: ReactElement;
  id: number;
  url: string;
}
const Footer = (): ReactElement => {
  const iconArr: Array<IconArrInterface> = [
    {
      icon: <TwitterIcon />,
      id: 1,
      url: "https://twitter.com/Scrol_ID",
    },
    {
      icon: <DiscordIcon />,
      id: 2,
      url: "https://discord.gg/B3KgkyGbQx",
    },
    //     {
    //     icon: <TelegramIcon />,
    //     id: 3,
    //     url: '',
    // },
    {
      icon: <MirrorIcon />,
      id: 4,
      url: "https://mirror.xyz/0x5A606B7B89F744Ed8ed723AE0390a82307dFF916",
    },
  ];

  const openLinkUrl = (url: string): void => {
    window.open(url, "_blank");
  };

  return (
    <div className="page-footer">
      <div className="page-footer-top">
        <div className="page-footer-icon-box">
          {iconArr.map((cur) => {
            return (
              <div
                className="page-footer-icon"
                key={cur.id}
                onClick={() => openLinkUrl(cur.url)}
              >
                {cur.icon}
              </div>
            );
          })}
        </div>
      </div>
      {/* <div className="page-footer-bottom">
        <div>{t("CXLsVyLwjANNrmRDOF")}</div>
        <div>{t("XTieISIliBrqFapIjE")}</div>
      </div> */}
    </div>
  );
};
export default Footer;
