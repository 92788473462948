import { AppProvider } from "./AppProvider";
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import Header from "./components/ui/Header";
import Footer from "./components/ui/Footer";
import Search from "./pages/search";
import MyPages from "./pages/my";
import NameDetail from "./pages/nameDetail";
import NameRegister from "./pages/register";
import RegisterSuccess from "./pages/registerSuccess";
import MysteryBox from "./pages/mysteryBox";
import { ReactComponent as MysteryLogoIcon } from "@/assets/svg/mystery-logo.svg";
import useTranslation from "@/hooks/useTranslation";
import classnames from "classnames";

export const App: React.FC<{}> = () => {
  return (
    <AppProvider>
      <AppDefault />
    </AppProvider>
  );
};

export const AppDefault = () => {
  let location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const isHome = location?.pathname === "/";

  const handlePointerMove = (e: any) => {
    const { clientX, clientY } = e;
    const rootBoxDom = document.querySelector(".root-box") as HTMLElement;
    const pageHeaderDom = document.querySelector(".page-header");
    if (e && pageHeaderDom?.contains(e.target)) {
      rootBoxDom?.classList.add("root-box-hide-after");
      return false;
    }
    rootBoxDom?.classList.remove("root-box-hide-after");
    if (rootBoxDom && isHome) {
      const { left, top } = rootBoxDom.getBoundingClientRect();
      rootBoxDom.style.background = `radial-gradient(500px circle at ${
        clientX - left
      }px ${clientY - top}px, rgba(209, 230, 125, 0.25), transparent 50%)`;
      rootBoxDom.style.setProperty("--x", clientX - left + "px");
      rootBoxDom.style.setProperty("--y", clientY - top + "px");
    }
  };

  return (
    <div
      className={classnames("root-box", {
        "root-box-other-pages": !isHome,
      })}
      onPointerMove={(e) => handlePointerMove(e)}
    >
      <div className="root-center">
        <Header />
        {location.pathname !== "/mystery/box" ? (
          <div
            className="blind-box"
            onClick={() => {
              navigate("/mystery/box");
            }}
          >
            <div className="blind-box-logo">
              <MysteryLogoIcon />
            </div>
            <span>{t("VhlPfqCTxcsxfBuCoWC")}</span>
          </div>
        ) : null}
        <div className="page-common-content">
          <Routes>
            <Route path="/" element={<Search />} />
            <Route path="/my" element={<MyPages />} />
            <Route path="/name/:name/view" element={<NameDetail />} />
            <Route path="/name/:name/register" element={<NameRegister />} />
            <Route path="/register/success" element={<RegisterSuccess />} />
            <Route path="/mystery/box" element={<MysteryBox />} />
            <Route path="/inviter/:address" element={<Search />} />
          </Routes>
        </div>
        <Footer />
      </div>
    </div>
  );
};
