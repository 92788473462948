import { useQuery } from "@tanstack/react-query";
import { getInviteRecordTop } from "@/pages/api";
import { useAppSelector } from "@/state/hooks";

const useInviteRecordTop = (address: string) => {
  const { personalSign } = useAppSelector((state: any) => state.user);
  const addressSig = personalSign[address] || "";
  const { data: list, isLoading } = useQuery({
    queryKey: ["inviteRecordTop", address, addressSig],
    refetchInterval: 5 * 60 * 1000,
    staleTime: 5 * 60 * 1000,
    queryFn: async () => {
      const useParams =
        address && personalSign[address]
          ? {
              wallet: address,
              sig: personalSign[address],
            }
          : null;
      const { data } = await getInviteRecordTop(useParams ? useParams : {});
      const result: any = [];
      if (data && data?.length) {
        data.forEach((item: any, index: number) => {
          item.rankNo = index + 1;
          result.push(item);
        });
      }
      return result;
    },
  });

  return {
    list,
    isLoading,
  };
};

export default useInviteRecordTop;
