import { useState, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';

const windowWidthValues = {
  xs: 0,
  sm: 740,
  md: 950,
  lg: 1254,
  drawer: 704,
};

const windowValues = ['xs', 'sm', 'md', 'lg', 'drawer'];

const widthUp = value => {
  return `(min-width:${windowWidthValues[value] + 0.05}px)`;
};
const widthDown = value => {
  return `(max-width:${windowWidthValues[value]}px)`;
};
const getWindowQuery = condition => {
  let result = '';
  windowValues.forEach(breakpoint => {
    const breakpointUp = condition[`${breakpoint}Up`];
    const breakpointDown = condition[`${breakpoint}Down`];
    if (breakpointUp) {
      result = widthUp(breakpoint);
    }
    if (breakpointDown) {
      result = widthDown(breakpoint);
    }
  });
  return result;
};
// xsDown = false, xsUp = false, smDown = false, smUp = false, mdDown = false, mdUp = false, lgDown = false, lgUp = false,
const Hidden = props => {
  const { children, ...condition } = props;
  const supportMatchMedia =
    typeof window !== 'undefined' && typeof window.matchMedia !== 'undefined';

  const query = getWindowQuery(condition);
  const matchMedia = window.matchMedia;
  const [match, setMatch] = useState(() => {
    return matchMedia(query).matches;
  });
  useLayoutEffect(() => {
    let active = true;
    if (!supportMatchMedia) {
      return undefined;
    }
    const queryList = matchMedia(query);
    const updateMatch = () => {
      if (active) {
        setMatch(queryList.matches);
      }
    };
    updateMatch();
    queryList.addListener(updateMatch);
    return () => {
      active = false;
      queryList.removeListener(updateMatch);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (match) {
    return null;
  }
  return children;
};

/**
 * 尺寸如果符合条件，则隐藏,down小于，up大于
 */
Hidden.propTypes = {
  children: PropTypes.node,
  xsDown: PropTypes.bool,
  xsUp: PropTypes.bool,
  /**
   * 小于740px 隐藏
   */
  smDown: PropTypes.bool,
  /**
   * 大于740px 隐藏
   */
  smUp: PropTypes.bool,
  /**
   * 小于950px 隐藏
   */
  mdDown: PropTypes.bool,
  /**
   * 大于950px 隐藏
   */
  mdUp: PropTypes.bool,
  /**
   * 小于1254px 隐藏
   */
  lgDown: PropTypes.bool,
  /**
   * 大于1254px 隐藏
   */
  lgUp: PropTypes.bool,
  /**
   * 小于704px 隐藏
   */
  drawerDown: PropTypes.bool,
  /**
   * 大于704px 隐藏
   */
  drawerUp: PropTypes.bool,
};

export default Hidden;
