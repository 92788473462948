import { useState, useMemo } from "react";
import { BigNumber } from "bignumber.js";
import type { JsonRpcProvider } from "@ethersproject/providers";
import { Interface } from "@ethersproject/abi";
import { yearsToSeconds } from "@/helper/utils";
import { utils as ethersUtils } from "ethers";
import { getRpcProvider } from "@/helper/utils/rpcProvider";
import { CHAIN_CONFIG } from "@/constants/chainInfo";

const useRegisterParams = (
  provider: JsonRpcProvider | undefined,
  account: string | undefined,
  chainId: any,
  name: string,
  durationYears: any,
  allFee: any,
  oneYearFee: any,
  userProof: any,
  nameStatus: any
) => {
  const [registerParams, setRegisterParams] = useState<any>({});

  useMemo(async () => {
    console.log("useRegisterParams", {
      provider,
      account,
      chainId,
      name,
      durationYears,
      allFee,
      oneYearFee,
      userProof: userProof,
      nameStatus,
    });
    if (
      provider &&
      account &&
      chainId &&
      name &&
      durationYears &&
      allFee &&
      oneYearFee &&
      userProof?.account &&
      userProof?.account === account &&
      nameStatus
    ) {
      setRegisterParams({
        callData: "",
        contractAddress: "",
        gasPrice: "",
        gasLimit: "",
        loading: true,
        value: "",
      });
      try {
        // const getChainId = await provider.send("eth_chainId", []);
        // console.log("chainIdchainId", chainId, useHexChainId);
        // if (chainId && chainId !== useHexChainId) {
        //   return;
        // }

        const REGISTRARCONTROLLER = CHAIN_CONFIG[chainId].REGISTRARCONTROLLER;
        const PUBLICRESOLVER = CHAIN_CONFIG[chainId].PUBLICRESOLVER;

        const functionName: string = "register";
        const iface: Interface = new Interface(REGISTRARCONTROLLER.abi);
        const resolverInterface: Interface = new Interface(PUBLICRESOLVER.abi);
        const duration = yearsToSeconds(durationYears);
        const params = {
          name: name,
          owner: account,
          duration: duration,
          resolver: PUBLICRESOLVER.address,
          reverseRecord: false,
          ownerControlledFuses: 0,
        };
        const callData = [
          resolverInterface.encodeFunctionData("setAddr(bytes32,address)", [
            ethersUtils.namehash(`${name}.scroll`),
            account,
          ]),
        ];
        let permission = 1;
        let proof: any = [];

        if (nameStatus === "release" && userProof.is_white_account) {
          permission = 8;
          proof = userProof.white_proof;
        } else if (nameStatus === "available" && userProof.is_ob_account) {
          permission = 64;
          proof = userProof.ob_proof;
        } else {
          permission = 1;
          proof = [];
        }
        console.log("useRegisterParams permission", permission);
        if (!permission) return;
        const data = callData;
        const getCallData = iface.encodeFunctionData(functionName, [
          params,
          data,
          permission,
          proof,
        ]);

        const payValue =
          permission === 64 && name.length > 4
            ? new BigNumber(allFee).minus(oneYearFee).shiftedBy(18).toFixed()
            : new BigNumber(allFee).shiftedBy(18).toFixed();
        // console.log("useRegisterParams payValuepayValue", payValue, permission);
        const rpcProvider: any = await getRpcProvider(chainId);
        const getProviderGasPrice: any = (await rpcProvider.getGasPrice())
          .mul(105)
          .div(100)
          .toString();
        const getGasLimit: any = "500000";
        console.log(
          "useRegisterParams getProviderGasPrice",
          getProviderGasPrice
        );
        if (nameStatus === "release" || nameStatus === "available") {
          setRegisterParams({
            callData: getCallData,
            gasPrice: getProviderGasPrice,
            contractAddress: REGISTRARCONTROLLER.address,
            gasLimit: getGasLimit,
            loading: false,
            value: payValue,
          });
        } else {
          setRegisterParams({
            callData: "",
            contractAddress: "",
            gasPrice: "",
            gasLimit: "",
            loading: false,
            value: "",
          });
        }
      } catch (err: any) {
        console.log("errerrerrerrerr account xx", err);
        setRegisterParams({
          callData: "",
          contractAddress: "",
          gasPrice: "",
          gasLimit: "",
          loading: false,
          value: "",
        });
      }
    } else if (chainId) {
      try {
        const REGISTRARCONTROLLER = CHAIN_CONFIG[chainId].REGISTRARCONTROLLER;
        const rpcProvider: any = await getRpcProvider(chainId);
        const getProviderGasPrice: any = (await rpcProvider.getGasPrice())
          .mul(105)
          .div(100)
          .toString();

        const getGasLimit: any = "500000";

        setRegisterParams({
          callData: "",
          gasPrice: getProviderGasPrice,
          contractAddress: REGISTRARCONTROLLER.address,
          gasLimit: getGasLimit,
          loading: false,
          value: "",
        });
      } catch (err: any) {
        console.log("errerrerrerrerr xx", err);
        setRegisterParams({
          callData: "",
          contractAddress: "",
          gasPrice: "",
          gasLimit: "",
          loading: false,
          value: "",
        });
      }
    }
  }, [
    provider,
    account,
    chainId,
    name,
    durationYears,
    allFee,
    oneYearFee,
    userProof?.account,
    nameStatus,
  ]);

  return useMemo(() => {
    return registerParams;
  }, [registerParams]);
};

export default useRegisterParams;
