import { ReactElement, useMemo, useState, useCallback } from "react";
import { useWeb3React } from "@web3-react/core";
import { useNavigate } from "react-router-dom";
import classnames from "classnames";
import dayjs from "dayjs";
import copyToClipboard from "copy-to-clipboard";
import { infoToastTip } from "@/components/ui/ToastTip";
import { useAppSelector, useAppDispatch } from "@/state/hooks";
import useInviteRecordTop from "@/hooks/useInviteRecordTop";
import useInviteRecord from "@/hooks/useInviteRecord";
import { showShortenAddress, showDomainAddress } from "@/utils";
import BigNumber from "bignumber.js";
import { ReactComponent as FirstIcon } from "@/assets/svg/first.svg";
import { ReactComponent as SecondIcon } from "@/assets/svg/second.svg";
import { ReactComponent as ThirdIcon } from "@/assets/svg/third.svg";
import { ReactComponent as CopyIcon } from "@/assets/svg/copy.svg";
import { ReactComponent as MysteryLogoIcon } from "@/assets/svg/mystery-logo.svg";
import { webClickEvent } from "@/helper/utils/sensors";
import { MysteryQuesIcon } from "../../assets/svg-iconfont";
import useTranslation from "@/hooks/useTranslation";
import { updateShowLoginDialog } from "@/state/user/reducer";
import MirHidden from "@/components/ui/MirHidden";
interface TopArrInterface {
  [key: number | string]: ReactElement;
}

interface StepsArrInterface {
  id: number;
  title: string;
  desc: string;
}

enum FilterTab {
  Ranking,
  MyInvite,
}

const MysteryBox = (): ReactElement => {
  const [tabs, setTabs] = useState("myInvite");
  const [currentFilterTab, setCurrentFilterTab] = useState(FilterTab.Ranking);
  const { account } = useWeb3React();
  const { userData } = useInviteRecord(account || "");
  const { t } = useTranslation();
  const { domainName } = useAppSelector((state) => state.domainManager);
  const { localRegistered } = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { list: rankingArr } = useInviteRecordTop(account || "");

  const topArr: TopArrInterface = {
    1: <FirstIcon />,
    2: <SecondIcon />,
    3: <ThirdIcon />,
  };

  const dateFormat: string = "YYYY-MM-DD HH:mm:ss";

  const stepsArr: Array<StepsArrInterface> = [
    {
      id: 1,
      title: t("SKAbNdhYxnIGHOkCFsp"),
      desc: t("seScdMrSkuOmXzCJumy"),
    },
    {
      id: 2,
      title: t("lfaOmnzvpoiBUqqQRbw"),
      desc: t("giMnelcasIpxAhbIgi"),
    },
    {
      id: 3,
      title: t("kgbEYBVCOfcqNLxuqS"),
      desc: t("nfSebzOfxBBXIVfabMZ"),
    },
    {
      id: 4,
      title: t("NUAuMBzFdVXNSwwYp"),
      desc: t("ayIDlrPSUiPjAtzCym"),
    },
  ];

  const onTabsFunc = (type: string): boolean | void => {
    if (type === tabs) {
      return false;
    }
    setTabs(type);
  };
  const origin = window.location.origin;

  const inviteLink = useMemo(() => {
    const registered =
      (account && localRegistered[account]) || userData?.is_registered
        ? true
        : false;
    if (origin && account && registered && userData?.invite_code) {
      return `${origin}/#/inviter/${userData?.invite_code}`;
    }
    return "";
  }, [origin, account, localRegistered, userData]);

  const tooltip = useCallback(({ key, children, tip }: any): ReactElement => {
    return (
      <div key={key} className="tooltip">
        {children}
        <div className="tip-container">
          <div className="tip-arrow" />
          {tip}
        </div>
      </div>
    );
  }, []);

  const clickCopy = (copyValue: string) => {
    copyToClipboard(copyValue);
    webClickEvent("Scroll_Ag_Invite");
    infoToastTip({
      status: "success",
      title: t("nBMZbjmYxYoLgLGLz"),
      toastId: `setUpdate${new Date().valueOf()}`,
    });
  };

  const mysteryBoxList = useMemo(() => {
    if (userData?.totalBox) {
      const result = [];
      for (let i = 0; i < userData.totalBox; i++) {
        result.push(i);
      }
      return result;
    }
    return [];
  }, [userData?.totalBox]);
  const renderRanking = ()=>{
    return (
      <div className="page-mystery-box-left">
        <div className="page-mystery-box-header">
          <div>{t("mbVSNXEjsftIvMjHJ")}</div>
          <div>{t("QAkVVWYnYkcEPOXpQY")}</div>
          <div className="page-mystery-box-header-mark">
            <span>{t("OKkrJZWBcRtICQpYXWaF")}</span>
            {tooltip({
              children: (
                <div className="page-mystery-box-header-tip">
                  <MysteryQuesIcon />
                </div>
              ),
              tip: <div>{t("ngMtQDeYgnmrnDKfxq")}</div>,
            })}
          </div>
        </div>
        <div className="page-mystery-box-line" />
        <div className="page-mystery-box-body">
          {rankingArr?.length
            ? rankingArr.map((cur: any) => {
                const inviter_domain = `${cur.inviter_domain}.scroll`;
                const flag =
                  domainName === inviter_domain ||
                  cur.inviter_domain === account;
                return (
                  <div
                    className={classnames("page-mystery-box-body-item", {
                      "page-mystery-box-body-item-my":
                        cur.address === account,
                    })}
                    key={cur.rankNo}
                  >
                    <div
                      className={classnames("", {
                        "page-mystery-box-body-item-rankNo":
                          cur.rankNo > 3 && flag,
                      })}
                    >
                      {cur.rankNo <= 3 ? topArr[cur.rankNo] : cur.rankNo}
                    </div>
                    <div
                      className={classnames("", {
                        "page-mystery-box-body-item-domain": flag,
                      })}
                    >
                      {cur?.inviter_address}
                    </div>
                    <div
                      className={classnames("", {
                        "page-mystery-box-body-item-invites": flag,
                      })}
                    >
                      {cur.invites}
                    </div>
                  </div>
                );
              })
            : null}
        </div>
      </div>
    )
  }
  const renderMyInvite = ()=>{
    return (
      <div className="page-mystery-box-right">
        <div className="page-mystery-box-right-invite">
          <div className="page-mystery-box-right-invite-desc">
            <div>
              <span>{t("wbwYpDDoOkTACMJM")}:</span>
              <span>{userData?.invite_wallets}</span>
            </div>
            <div>
              <span>{t("UnVwHctiZCavcPPtt")}:</span>
              <span>{userData?.invitee_domains}</span>
            </div>
            <div>
              <span>{t("yqymLAVhLpWvAMstuzK")}:</span>
              <span>{userData?.rank ? userData.rank : "--"}</span>
            </div>
          </div>
          <div className="page-mystery-box-right-invite-link">
            <div className="page-mystery-box-right-invite-link_title">
              {t("WrYGdWDwHDkKUSnQrPj")}
            </div>
            {!account ? (
              <div
                className="page-mystery-box-right-invite-login"
                onClick={() => {
                  dispatch(
                    updateShowLoginDialog({ showLoginDialog: true })
                  );
                }}
              >
                {t("yeiiOdDynPCxirwkYEV")}
              </div>
            ) : null}
            {account && inviteLink?.length ? (
              <div className="page-mystery-box-right-invite-link-copy">
                <span className="page-mystery-box-right-invite-link_inviteLink">
                  {inviteLink}
                </span>
                <div
                  onClick={() => {
                    clickCopy(inviteLink);
                  }}
                >
                  <CopyIcon />
                </div>
              </div>
            ) : null}
            {account && !inviteLink?.length ? (
              <div
                className="page-mystery-box-right-invite-login"
                onClick={() => {
                  navigate(`/`);
                }}
              >
                {t("zzntSzvzVdCUadwXzi")}
              </div>
            ) : null}
          </div>
        </div>
        <div className="page-mystery-box-right-step">
          <div className="page-mystery-box-right-step-icon">
            <div>
              <MysteryLogoIcon />
              {userData?.invite_wallets?.toString() &&
              userData?.invite_wallets < 30 ? (
                <div className="page-mystery-box-right-step-icon-num">
                  {t("aALmkNDIAuxCnixx", {
                    stepTotal: userData?.stepTotal,
                  })}
                  （{userData?.currentTotal}/{userData?.stepTotal}）
                </div>
              ) : null}
            </div>
          </div>
          <div className="page-mystery-box-right-step-desc">
            <ol className="page-mystery-box-right-step-desc-ol">
              {stepsArr.map(
                (cur: StepsArrInterface, index: number): ReactElement => {
                  const lastIndex = stepsArr.length - 1;
                  return (
                    <li key={cur.id}>
                      {index !== lastIndex && (
                        <div className="page-mystery-box-right-step-desc-ol-line" />
                      )}
                      <div className="page-mystery-box-right-step-desc-ol-item">
                        <p>
                          <span>{cur.title}</span>
                          <span>{cur.desc}</span>
                        </p>
                      </div>
                    </li>
                  );
                }
              )}
            </ol>
          </div>
        </div>
        {account ? (
          <div className="page-mystery-box-right-list">
            <div className="page-mystery-box-right-list-tabs">
              <div
                className={
                  tabs === "myInvite"
                    ? "page-mystery-box-right-list-tabs-active"
                    : ""
                }
                onClick={() => onTabsFunc("myInvite")}
              >
                {t("nbbyfPBKGSJSUpgxgKe")}
              </div>
              <div
                className={
                  tabs === "myBox"
                    ? "page-mystery-box-right-list-tabs-active"
                    : ""
                }
                onClick={() => onTabsFunc("myBox")}
              >
                {t("UJciZbNHxalJNfFRgTS")}
              </div>
            </div>
            {tabs === "myInvite" && (
              <div className="page-mystery-box-right-invite-list">
                <div className="page-mystery-box-right-invite-list-header">
                  <div>{t("SbrstdbBEFJKPyuoNui")}</div>
                  <div>{t("uafThtUsXpwbvDtOH")}</div>
                  <div>{t("LPYPtVnfpRJRGMAlGR")}</div>
                  <div>{t("NAUjGxGUidAbmdwVL")}</div>
                </div>
                {userData?.records?.length ? (
                  <div className="page-mystery-box-right-invite-list-body">
                    {userData.records.map(
                      (cur: any, index: number): ReactElement => {
                        const showAmount = cur?.amount_in_eth
                          ? new BigNumber(cur.amount_in_eth)
                              .shiftedBy(-18)
                              .toFixed()
                          : "0";
                        const showDate = cur?.timestamp
                          ? dayjs(cur.timestamp * 1000).format(dateFormat)
                          : "--";
                        return (
                          <div
                            className="page-mystery-box-right-invite-list-item"
                            key={`${index}${cur.domain_name}`}
                          >
                            <div>
                              {cur.invitee
                                ? showShortenAddress(cur.invitee)
                                : ""}
                            </div>
                            <div>{showDomainAddress(cur.domain_name)}</div>
                            <div>
                              {showAmount ? showAmount + " ETH" : ""}
                            </div>
                            <div>{showDate}</div>
                          </div>
                        );
                      }
                    )}
                  </div>
                ) : (
                  <div className="page-mystery-box-right-invite-list-no-data">
                    {t("SglOJmTylVgfMcsCXI")}
                  </div>
                )}
              </div>
            )}
            {tabs === "myBox" && (
              <div className="page-mystery-box-right-box-list">
                {mysteryBoxList.length ? (
                  <div className="page-mystery-box-right-box-list-center">
                    <div className="page-mystery-box-right-box-list-wrap">
                      {mysteryBoxList.map((cur) => (
                        <div key={cur}>
                          <MysteryLogoIcon />
                        </div>
                      ))}
                    </div>
                  </div>
                ) : (
                  <div className="page-mystery-box-right-box-list-no-data">
                    <div>
                      <MysteryLogoIcon />
                    </div>
                    <div>{t("sNpmfKEdcHSoPQSkA")}</div>
                    <p>{t("zkegBzeIXipBSjlCs")}</p>
                  </div>
                )}
              </div>
            )}
          </div>
        ) : null}
      </div>
    )
  }
  return (
    <div className="page-mystery-box">
      <div className="page-mystery-box-content">
        <MirHidden smUp>
          <div className="page-mystery-box-switch">
            <div className={classnames('tab-item',{
              'tab-item-selected':currentFilterTab === FilterTab.Ranking
              })} onClick={()=>{
                setCurrentFilterTab(FilterTab.Ranking);
              }}>
              {'榜单'}
            </div>
            <div className={classnames('tab-item',{
              'tab-item-selected':currentFilterTab === FilterTab.MyInvite
              })} onClick={()=>{
                setCurrentFilterTab(FilterTab.MyInvite);
              }}>
             {t('nbbyfPBKGSJSUpgxgKe')} 
            </div>
          </div>
        </MirHidden>
        <MirHidden smDown>
          <div className="page-mystery-box-title">{t("flsbrcNQHLGakFGvWQZ")}</div>
        </MirHidden>
        <div className="page-mystery-box-center">
          <MirHidden smUp>
            { currentFilterTab === FilterTab.Ranking && renderRanking()}
            {currentFilterTab === FilterTab.MyInvite && renderMyInvite()}
          </MirHidden>
          <MirHidden smDown>
            {renderRanking()}
            {renderMyInvite()}
          </MirHidden>
        </div>
      </div>
    </div>
  );
};

export default MysteryBox;
