import { toast } from "react-toastify";
import classnames from "classnames";
import { ReactComponent as TipSuccess } from "@/assets/svg/tip_success.svg";
import { ReactComponent as TipFail } from "@/assets/svg/tip_fail.svg";
import { ReactComponent as CloseIcon } from "@/assets/svg/close.svg";
// import { ReactComponent as LoadingIcon } from "@/assets/svg/tip_loading.svg";

export const txToastTip = ({
  status,
  title,
  hash,
  sendAmount,
  reciveAmount,
  symbol,
  viewText,
  onView,
}: {
  status: string;
  title: string;
  hash: string;
  sendAmount: string;
  reciveAmount: string;
  symbol: string;
  viewText: string;
  onView: () => void;
}) => {
  const autoClose = 6000;
  const getToastSetObject = {
    autoClose,
    hideProgressBar: true,
    icon: false,
    closeButton: false,
    toastId: hash,
  };
  console.log("--当前展示的时间toastTip-date---", getToastSetObject);

  const renderContent = () => (
    <div>
      <div
        className={classnames("toast-status-title", {
          "success-content": status === "success" ? true : false,
          "fail-content": status === "fail" ? true : false,
        })}
      >
        {status === "success" && <TipSuccess />}
        {status === "fail" && <TipFail />}
        <div className="toast-status-title_title">{title}</div>
        <CloseIcon />
      </div>
      <div
        onClick={(e) => {
          e.stopPropagation();
          onView();
        }}
        className="toast-tx-info"
      >
        <div>
          {sendAmount} {symbol} {`->`} {reciveAmount} {symbol}
        </div>
        <div className="toast-tx-info_view">{viewText}</div>
      </div>
    </div>
  );

  toast.success(renderContent(), {
    ...getToastSetObject,
  });
  return null;
};

export const infoToastTip = ({
  status,
  title,
  des,
  toastId,
  children,
}: {
  status: string;
  title: string;
  toastId: string;
  des?: string;
  children?:any;
}) => {
  const autoClose = 3000;
  const getToastSetObject = {
    autoClose,
    hideProgressBar: true,
    icon: false,
    closeButton: false,
    toastId,
  };

  const renderContent = () => (
    <div>
      <div
        className={classnames("toast-status-title", {
          "success-content": status === "success" ? true : false,
          "fail-content": status === "fail" ? true : false,
        })}
      >
        {status === "success" && <TipSuccess />}
        {status === "fail" && <TipFail />}
        <div className="toast-status-title_title">{title}</div>
        <div className="tip-close">
          <CloseIcon />
        </div>
      </div>
      {des && <div className="toast-tx-info-des">{des}</div>}
      {children}
    </div>
  );

  toast.success(renderContent(), {
    ...getToastSetObject,
  });
  return null;
};
