import React, { ReactElement, useState, useMemo } from "react";
import { DialogOverlay, DialogContent } from "@reach/dialog";
import classNames from "classnames";
import useRentPrice from "@/hooks/useRentPrice";
import { useWeb3React } from "@web3-react/core";
import useNameReNew from "@/hooks/useNameReNew";
import BigNumberJs from "bignumber.js";
import { nameRenew } from "@/helper/contract";
import { txAwait } from "@/helper/utils/txAwait";
import { infoToastTip } from "@/components/ui/ToastTip";
import { getUsdPrice } from "@/utils";
import { ReactComponent as CloseIcon } from "@/assets/svg/close.svg";
import { ReactComponent as CopyIcon } from "@/assets/svg/copy.svg";
import { ReactComponent as MinusIcon } from "@/assets/svg/minus.svg";
import { ReactComponent as PlusIcon } from "@/assets/svg/plus.svg";
import { webClickEvent } from "@/helper/utils/sensors";
import useTranslation from "@/hooks/useTranslation";
import copyToClipboard from "copy-to-clipboard";
import { initChainId } from "@/constants";
import { CHAIN_CONFIG } from "@/constants/chainInfo";

interface NameReletDialogInterface {
  visible: boolean;
  onCancel?(event: React.MouseEvent | React.KeyboardEvent): void;
  onOk: any;
  name: string;
  registrant: string;
}

const NameReletDialog = (props: NameReletDialogInterface): ReactElement => {
  const { visible, onCancel, onOk, name, registrant } = props;

  const [buyLoading, setBuyLoading] = useState(false);
  const [year, setYear] = useState<any>(1);
  const [flag, setFlag] = useState(false);
  const { provider, account, chainId } = useWeb3React();

  const { t } = useTranslation();

  const useChainId = useMemo(() => {
    if (chainId && CHAIN_CONFIG[chainId]) {
      return chainId;
    }
    return initChainId;
  }, [chainId]);

  const useYear = useMemo(() => {
    if (year) {
      return year;
    }
    return 1;
  }, [year]);

  const { allFee, balance } = useRentPrice({
    name: name || "",
    years: useYear ? useYear.toString() : "0",
    account,
    chainId: useChainId,
  });

  const { callData, gasPrice, gasLimit, contractAddress } = useNameReNew(
    provider,
    account,
    name || "",
    useYear,
    allFee,
    visible,
    useChainId
  );

  // 减
  const minusFunc = (): void => {
    setYear(Number(year) - 1);
  };

  // 加
  const plusFunc = (): void => {
    if (year && year > 99) {
      setYear(100);
      return;
    }
    setYear(Number(year) + 1);
  };

  const yearChange = (e: any): Boolean | void => {
    const val = e.target.value;
    console.log(val, "yearChange");
    if (!val) {
      setYear("");
      return;
    }
    // 非零的正整数
    if (!val) {
      setYear(1);
      return;
    }
    const re = /^[1-9]\d*$/;
    if (!e.target.value || !re.test(val)) {
      return false;
    }
    if (e.target.value > 100) {
      setYear(100);
      return;
    }
    setYear(e.target.value);
  };

  const yearBlur = (e: any) => {
    const val = e?.target?.value ? e?.target?.value : 1;
    setFlag(false);
    setYear(val);
  };

  const showGas = useMemo(() => {
    if (gasPrice & gasLimit) {
      const result = new BigNumberJs(gasPrice)
        .multipliedBy(gasLimit)
        .shiftedBy(-18)
        .toFixed();
      return result;
    }
    return "";
  }, [gasPrice, gasLimit]);

  const showTotal = useMemo(() => {
    let result: any = "--";
    if (allFee) {
      result = allFee;
      if (showGas) {
        result = new BigNumberJs(result).plus(showGas).toFixed();
      }
    }
    return result;
  }, [showGas, allFee]);

  const minusFlag: boolean = year <= 1;

  const buyEnable =
    provider &&
    account &&
    contractAddress &&
    gasPrice &&
    gasLimit &&
    callData &&
    allFee;

  const confimbuy = async () => {
    if (buyEnable) {
      setBuyLoading(true);
      try {
        if (
          balance &&
          allFee &&
          new BigNumberJs(allFee).shiftedBy(18).isGreaterThan(balance)
        ) {
          infoToastTip({
            status: "fail",
            title: t("CVaasqzogQmyMJriVJ"),
            toastId: `checkbalance${new Date().valueOf()}`,
          });
          setBuyLoading(false);
          return;
        }
        const tx: any = await nameRenew(
          provider,
          account,
          chainId,
          contractAddress,
          callData,
          allFee,
          t
        );
        if (!tx?.hash) {
          throw new Error("buy namr not hash");
        }
        const result = await txAwait(provider, tx.hash);
        console.log("result", result);
        webClickEvent("Scroll_Ag_Renew");
        if (result.status === 1) {
          infoToastTip({
            status: "success",
            title: t("oCswqahnMJBIIgbTSPx"),
            toastId: `priceUpdate${new Date().valueOf()}`,
          });
          onOk(useYear);
          setBuyLoading(false);
          return;
        }
        setBuyLoading(false);
        infoToastTip({
          status: "fail",
          title: t("ZCAxZXIhjAqfaXiItmh"),
          toastId: `priceUpdate${new Date().valueOf()}`,
        });
      } catch (err: any) {
        setBuyLoading(false);
        const showErr =
          err && typeof err === "string" ? err : t("ZCAxZXIhjAqfaXiItmh");
        infoToastTip({
          status: "fail",
          title: showErr,
          toastId: `priceUpdate${new Date().valueOf()}`,
        });
      }
    }
  };

  console.log("buyEnablebuyEnable", buyEnable);
  const clickCopy = (copyValue: string) => {
    copyToClipboard(copyValue);
    infoToastTip({
      status: "success",
      title: t("nBMZbjmYxYoLgLGLz"),
      toastId: `setUpdate${new Date().valueOf()}`,
    });
  };

  const isMyDomain = account === registrant;
  return (
    <DialogOverlay isOpen={visible} dangerouslyBypassFocusLock>
      <DialogContent className="name-relet-modal">
        <div className="name-relet-modal-content">
          <div className="name-relet-modal-close-icon">
            {!buyLoading ? (
              <div onClick={onCancel}>
                <CloseIcon />
              </div>
            ) : null}
          </div>
          <div className="name-relet-modal-title">
            {t("NYyjrtUvEscDdcTUENbq")}
          </div>
          <div className="name-relet-modal-name">
            <div className="name-relet-modal-name-label">{name}.scroll</div>
            <div
              className="name-relet-modal-name-copy"
              onClick={() => {
                clickCopy(`${name}.scroll`);
              }}
            >
              <CopyIcon />
            </div>
          </div>
          <div className="name-relet-modal-control">
            <div
              className={classNames("name-relet-control-minus-plus", {
                "name-relet-control-minus-plus-disabled": minusFlag,
              })}
              onClick={minusFlag ? () => {} : minusFunc}
            >
              <MinusIcon />
            </div>
            {flag ? (
              <div className="name-relet-control-input">
                <input
                  onChange={yearChange}
                  onBlur={yearBlur}
                  value={year}
                  pattern="[0-9]*"
                  min={1}
                  type="number"
                  autoFocus={false}
                />
              </div>
            ) : (
              <div
                className="name-relet-control-input"
                onClick={() => setFlag(true)}
              >
                {t("AIvFwbIrTWQVnBUGBw", {
                  year: year,
                  more: year > 1 ? "s" : "",
                })}
              </div>
            )}
            <div
              className={classNames("name-relet-control-minus-plus", {
                "name-relet-control-minus-plus-disabled": flag,
              })}
              onClick={flag ? () => {} : plusFunc}
            >
              <PlusIcon />
            </div>
          </div>
          <div className="name-relet-modal-desc">
            <div className="name-relet-modal-desc-item mb20">
              <div className="name-relet-modal-desc-item-label">
                {t("IvUjvBTpxYhAbkkqMN", { year: useYear })}
              </div>
              <div className="name-relet-modal-desc-item-value">
                {allFee} ETH {getUsdPrice(allFee)}
              </div>
            </div>
            <div className="name-relet-modal-desc-item mb20">
              <div className="name-relet-modal-desc-item-label">
                {t("cGQUDgYgOpFIJaVpLI")}
              </div>
              <div className="name-relet-modal-desc-item-value">
                {showGas} ETH {getUsdPrice(showGas)}
              </div>
            </div>
            <div className="name-relet-modal-desc-item-line" />
            <div className="name-relet-modal-desc-item">
              <div className="name-relet-modal-desc-item-label">
                {t("YfclQcbcgrggHxaGV")}
              </div>
              <div className="name-relet-modal-desc-item-value">
                {showTotal} ETH {getUsdPrice(showTotal)}
              </div>
            </div>
          </div>
          {!isMyDomain && (
            <div className="name-relet-my-domain">{t("IHzkCpqqmvjWCaEEd")}</div>
          )}
          <div
            className={classNames("name-relet-submit-btn", {
              "btn-disable": !buyEnable,
              "btn-loading": buyLoading,
            })}
            onClick={confimbuy}
          >
            {buyLoading ? <div className="btn-loading-icon" /> : null}
            {buyLoading ? t("hauYoTIzrwJLYjdD") : t("hgvNASEYHzmiBaqXlx")}
          </div>
        </div>
      </DialogContent>
    </DialogOverlay>
  );
};

export default NameReletDialog;
