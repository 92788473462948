import { useEffect, useState } from "react";
import { useWeb3React } from "@web3-react/core";
import { useAppDispatch } from "@/state/hooks";
import {
  updatePersonalSign,
  updateEthPrice,
  updateHistorySearch,
  updateUserProof,
} from "./reducer";
import { store as reduxStore } from "@/state";
import { useQuery } from "@tanstack/react-query";
import { getEthPrice } from "@/pages/api";
import { getProof } from "@/pages/api";
import { infoToastTip } from "@/components/ui/ToastTip";
import useTranslation from "@/hooks/useTranslation";
import { initChainId } from "@/constants";
import { CHAIN_CONFIG } from "@/constants/chainInfo";
import useSelectChain from "@/hooks/useSelectChain";
import { updateSelectedWallet } from "./reducer";
import { ConnectionType } from "@/connection";

export default function UserInit() {
  const dispatch = useAppDispatch();
  const [loginAccount, setLoginAccount] = useState("");
  const { account, provider, chainId, isActive } = useWeb3React();
  const { t } = useTranslation();
  const selectChain = useSelectChain();

  useEffect(() => {
    if (window.self !== window.top) {
      reduxStore.dispatch(
        updateSelectedWallet({ wallet: ConnectionType.INNEROPENBLOCK })
      );
    }
  }, []);

  useEffect(() => {
    if (account) {
      setLoginAccount(account);
    } else {
      setLoginAccount("");
    }
  }, [account]);

  useEffect(() => {
    if (loginAccount && chainId && !CHAIN_CONFIG[chainId] && isActive) {
      selectChain(initChainId);
    }
  }, [loginAccount, chainId, isActive]);

  // sig
  useEffect(() => {
    if (loginAccount && provider && isActive) {
      const signer = provider?.getSigner();
      const state = reduxStore.getState();
      const {
        user: { personalSign },
      } = state;
      if (!personalSign[loginAccount]) {
        signer?.signMessage(loginAccount).then((res) => {
          const payload = { [loginAccount]: res };
          dispatch(updatePersonalSign(payload));
        });
      }
    }
  }, [loginAccount, provider, isActive]);

  useQuery({
    queryKey: ["ethprice"],
    refetchInterval: 3 * 60 * 1000,
    staleTime: 3 * 60 * 1000,
    queryFn: async () => {
      try {
        const { data } = await getEthPrice();
        if (data) {
          console.log("priceprice data", data);
          dispatch(updateEthPrice({ ethPrice: data }));
        }
      } catch {}
      return null;
    },
  });

  const { data } = useQuery({
    queryKey: ["userProof", loginAccount],
    enabled: loginAccount ? true : false,
    refetchInterval: 3 * 60 * 1000,
    staleTime: 3 * 60 * 1000,
    queryFn: async () => {
      try {
        const { data }: any = await getProof({ account: loginAccount });
        if (data) {
          return data;
        }
        return {};
      } catch {
        infoToastTip({
          status: "fail",
          title: t("YRdVoqyWhpdAqYcilT"),
          toastId: `userProof${new Date().valueOf()}`,
        });
        return {};
      }
    },
  });

  console.log("userProof data", data, loginAccount);
  useEffect(() => {
    if (data?.length > 0 && loginAccount) {
      const newData: any = {};
      newData.account = loginAccount;
      data.forEach((item: any) => {
        if (item.permission === 64) {
          newData.is_ob_account = true;
          newData.ob_proof = item.proof.proof;
        }
        if (item.permission === 8) {
          newData.is_white_account = true;
          newData.white_proof = item.proof.proof;
        }
        if (item.permission === 1) {
          newData.permission = 1;
          newData.proof = [];
        }
      });
      console.log("userProof", newData);
      dispatch(updateUserProof({ userProof: newData }));
    } else {
      console.log("userProof new", {});
      dispatch(updateUserProof({ userProof: {} }));
    }
  }, [data, loginAccount]);

  return null;
}

export const setHistorySearch = (value: string) => {
  const state = reduxStore.getState();
  const {
    user: { historySearch },
  } = state;
  let result: any = [];
  if (historySearch.includes(value)) {
    result = historySearch.filter((item: string) => item !== value);
    result.push(value);
  } else {
    result = [...historySearch];
    result.push(value);
    if (result.length > 5) {
      result.shift();
    }
  }
  reduxStore.dispatch(updateHistorySearch({ historySearch: result }));
};
