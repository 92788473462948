import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState, useMemo } from "react";
import classnames from "classnames";
import { unstable_batchedUpdates } from "react-dom";
import { ReactComponent as CopyIcon } from "@/assets/svg/copy.svg";
import { ReactComponent as LinkIcon } from "@/assets/svg/link.svg";
import { NameDetailsCancelIcon } from "@/assets/svg-iconfont";
import NameReletDialog from "@/components/ui/NameReletDialog";
import NameSetDialog from "@/components/ui/NameSetDialog";
import { nameDetail } from "@/helper/contract";
import { yearsToSeconds } from "@/helper/utils";
import dayjs from "dayjs";
import { useWeb3React } from "@web3-react/core";
import { infoToastTip } from "@/components/ui/ToastTip";
import { openNewTabURL } from "@/utils";
import copyToClipboard from "copy-to-clipboard";
import useTranslation from "@/hooks/useTranslation";
import { initChainId } from "@/constants";
import { CHAIN_CONFIG } from "@/constants/chainInfo";

const NameDetail = () => {
  const { name: paramsName }: any = useParams();
  const name = paramsName ? paramsName.toLowerCase() : "";
  const navigate = useNavigate();
  const { account, chainId } = useWeb3React();
  const { t } = useTranslation();

  const useChainId = useMemo(() => {
    if (chainId && CHAIN_CONFIG[chainId]) {
      return chainId;
    }
    return initChainId;
  }, [chainId]);

  const [registrant, setRegistrant] = useState("");
  const [controller, setController] = useState("");
  const [expiryData, setExpiryData] = useState(0);

  const [reletVisible, setReletVisible] = useState(false);
  const [transSetVisible, setTransSetVisible] = useState(false);
  const [transSetType, setTransSetType] = useState("set");

  const dateFormat: string = "YYYY-MM-DD HH:mm:ss";

  useEffect(() => {
    if (name && useChainId) {
      nameDetail(name, useChainId).then((res: any) => {
        console.log("resresres", res);
        setRegistrant(res.registrant);
        setController(res.controller);
        setExpiryData(Number(res.expiryData) * 1000);
      });
    }
  }, [name, useChainId]);

  const goBackFunc = (): void => {
    navigate(-1);
  };

  const onShowReletFunc = (): void => {
    setReletVisible(true);
  };

  const onReletCallbackFunc = (year: number): void => {
    setReletVisible(false);
    if (expiryData) {
      const newExpiryData = expiryData + yearsToSeconds(year) * 1000;
      setExpiryData(newExpiryData);
    }
  };

  const onTransSetCallbackFunc = (type: string, val: string): void => {
    if (type === "transfer") {
      setRegistrant(val);
    } else {
      setController(val);
    }
    infoToastTip({
      status: "success",
      title: t("rSRnTfRpXgCYZglga"),
      toastId: `setUpdate${new Date().valueOf()}`,
    });
    setTransSetVisible(false);
  };

  const onTransferSetFunc = (type: string): void => {
    unstable_batchedUpdates(() => {
      setTransSetType(type);
      setTransSetVisible(true);
    });
  };

  const viewAddress = (address: string) => {
    const blockExplorerAddrUrl = CHAIN_CONFIG[useChainId]?.blockExplorerAddrUrl;
    if (!blockExplorerAddrUrl) return;
    openNewTabURL(`${blockExplorerAddrUrl}${address}`);
  };
  const clickCopy = (copyValue: string) => {
    copyToClipboard(copyValue);
    infoToastTip({
      status: "success",
      title: t("nBMZbjmYxYoLgLGLz"),
      toastId: `setUpdate${new Date().valueOf()}`,
    });
  };
  const woner = account && account === registrant;

  return (
    <div className="page-name-detail">
      <div className="page-name-detail-content">
        <div className="page-name-detail-cancel" onClick={goBackFunc}>
          <div className="page-name-detail-cancel-btn">
            <NameDetailsCancelIcon />
            <span>{t("kyyeXWyPVicVGcWAEwLc")}</span>
          </div>
        </div>
        <div className="page-name-detail-title">
          <div className="page-name-detail-title-name">
            <span>{t("RuQxFnHErSWaTUwzYUqp", { name: `${name}.scroll` })}</span>
            <div
              onClick={() => {
                clickCopy(`${name}.scroll`);
              }}
            >
              <CopyIcon />
            </div>
          </div>
          {account ? (
            <div
              className="page-name-detail-title-btn"
              onClick={onShowReletFunc}
            >
              {t("NYyjrtUvEscDdcTUENbq")}
            </div>
          ) : null}
        </div>
        <div className="page-name-detail-desc">
          <div
            className={classnames("page-name-detail-desc-item", {
              mb10: woner,
            })}
          >
            <div className="page-name-detail-desc-item-label">
              {t("rMVYLNIpwODyJbwpZf")}
            </div>
            <div className="page-name-detail-desc-item-val">
              <span>{registrant}</span>
              <span
                onClick={() => {
                  clickCopy(registrant);
                }}
              >
                <CopyIcon />
              </span>
              <span
                onClick={() => {
                  viewAddress(registrant);
                }}
              >
                <LinkIcon />
              </span>
            </div>
          </div>
          {woner && (
            <div
              className="page-name-detail-desc-transfer-set"
              onClick={() => onTransferSetFunc("transfer")}
            >
              <div>{t("hCCIxzZgmvVUHEadUj")}</div>
            </div>
          )}
          <div
            className={classnames("page-name-detail-desc-item", {
              mb10: woner,
            })}
          >
            <div className="page-name-detail-desc-item-label">
              {t("SNRQKvoBlesLvPThIiQ")}
            </div>
            <div className="page-name-detail-desc-item-val">
              <span>{controller}</span>
              <span
                onClick={() => {
                  clickCopy(controller);
                }}
              >
                <CopyIcon />
              </span>
              <span
                onClick={() => {
                  viewAddress(controller);
                }}
              >
                <LinkIcon />
              </span>
            </div>
          </div>
          {woner && (
            <div
              className="page-name-detail-desc-transfer-set"
              onClick={() => onTransferSetFunc("set")}
            >
              <div>{t("MaPGwoWxkGybeCdXRc")}</div>
            </div>
          )}
          <div className="page-name-detail-desc-item">
            <div className="page-name-detail-desc-item-label">
              {t("PiWcYFfCMeaPffHSzpH")}
            </div>
            <div className="page-name-detail-desc-item-val">
              {expiryData ? dayjs(expiryData).format(dateFormat) : "--"}
            </div>
          </div>
        </div>
      </div>
      <NameReletDialog
        name={name || ""}
        registrant={registrant}
        visible={reletVisible}
        onOk={onReletCallbackFunc}
        onCancel={() => setReletVisible(false)}
      />
      {transSetVisible && (
        <NameSetDialog
          visible={transSetVisible}
          registrant={registrant}
          controller={controller}
          name={name || ""}
          onOk={onTransSetCallbackFunc}
          onCancel={() => setTransSetVisible(false)}
          type={transSetType}
          defaultOwner={controller === account} // 根据nameDetail zeroOwner判断
        />
      )}
    </div>
  );
};

export default NameDetail;
