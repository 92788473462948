import { useEffect, useState } from "react";
import { useWeb3React } from "@web3-react/core";
import { useAppDispatch } from "@/state/hooks";
import useNameForAddr from "@/hooks/useNameForAddr";
import {
  updateDomainName,
  updateDomainList,
  updateLocalDomainList,
} from "./reducer";
import { useQuery } from "@tanstack/react-query";
import { getMyDomainList } from "@/pages/api";
import { store as reduxStore } from "@/state";

export const addLocalDomainName = (address: string, domain: any) => {
  const state = reduxStore.getState();
  const {
    domainManager: { localDomainList, domainList },
  } = state;
  const useLocalDomainList = localDomainList[address] || [];
  const havaNew = useLocalDomainList
    ? useLocalDomainList.filter((item: any) => item.name === domain.name)
    : [];
  const successHava = domainList
    ? domainList.filter((item: any) => item.name === domain.name)
    : [];
  const resultSearch = [...havaNew, ...successHava];
  if (!resultSearch || resultSearch.length < 1) {
    const payload = { [address]: [...useLocalDomainList, domain] };
    console.log("addLocalDomainName newArr", payload);
    reduxStore.dispatch(updateLocalDomainList(payload));
  }
};

export const updataOwnerDomainName = (domain: any) => {
  const state = reduxStore.getState();
  const {
    domainManager: { domainList },
  } = state;
  const result: any = [];
  domainList.forEach((item: any) => {
    let newDomain = { ...item };
    if (newDomain.name === domain.name) {
      if (newDomain.domainsController) {
        newDomain.owner = domain.owner;
        newDomain.last_hash = domain.last_hash;
        newDomain.domainsOwner = domain.domainsOwner;
        result.push(newDomain);
      }
    } else {
      result.push(item);
    }
  });
  console.log("resultresultresult", result);
  reduxStore.dispatch(updateDomainList({ domainList: result }));
};

export const updataControllerDomainName = (domain: any) => {
  const state = reduxStore.getState();
  const {
    domainManager: { domainList },
  } = state;
  const result: any = [];
  domainList.forEach((item: any) => {
    let newDomain = { ...item };
    if (newDomain.name === domain.name) {
      newDomain.domainsController = domain.domainsController;
      newDomain.last_hash = domain.last_hash;
      newDomain.controller = domain.controller;
      result.push(newDomain);
    } else {
      result.push(item);
    }
  });
  reduxStore.dispatch(updateDomainList({ domainList: result }));
};

export const updataMyDomainList = async (
  account: string,
  chainIdParams: number
) => {
  try {
    if (!account || !chainIdParams) return;
    console.log("updataMyDomainList start");
    const { data } = await getMyDomainList({
      account,
      chain_id: chainIdParams,
    });
    const result: any = [];
    if (data?.owner_domains && data?.owner_domains?.length > 0) {
      data?.owner_domains.forEach((item: any) => {
        item.domainsOwner = true;
        result.push(item);
      });
    }
    if (data?.controller_domains && data?.controller_domains?.length > 0) {
      data?.controller_domains.forEach((item: any) => {
        let newDomains = result.filter((resultItem: any) => {
          if (resultItem.name === item.name) {
            resultItem.domainsController = true;
            return true;
          } else {
            return false;
          }
        });
        if (newDomains?.length > 0) {
        } else {
          item.domainsController = true;
          result.push(item);
        }
      });
    }
    const state = reduxStore.getState();
    const {
      domainManager: { localDomainList },
    } = state;

    const useLocalDomainList = localDomainList[account] || [];

    if (useLocalDomainList && useLocalDomainList?.length > 0) {
      const nameString = result.map((item: any) => item.name);
      const newLocalArr: any = [];
      useLocalDomainList.forEach((item: any) => {
        if (!nameString.includes(item.name)) {
          //已经拉取的删除
          newLocalArr.push(item);
        }
      });
      if (useLocalDomainList?.length !== newLocalArr?.length) {
        const payload = { [account]: [...newLocalArr] };
        reduxStore.dispatch(updateLocalDomainList(payload));
      }
    }
    reduxStore.dispatch(updateDomainList({ domainList: result }));
  } catch {}
};

export default function DomainManagerInit() {
  const [listInit, setListInit] = useState(false);
  const dispatch = useAppDispatch();
  const { account, chainId } = useWeb3React();
  const addrName = useNameForAddr(account, chainId);

  useEffect(() => {
    if (addrName) {
      dispatch(updateDomainName({ name: addrName }));
    } else {
      dispatch(updateDomainName({ name: "" }));
    }
  }, [addrName]);

  const isEnabled = chainId && account ? true : false;
  const { refetch } = useQuery({
    queryKey: ["myDomainLis", chainId, account],
    enabled: isEnabled,
    refetchInterval: 3 * 60 * 1000,
    staleTime: 3 * 60 * 1000,
    queryFn: async () => {
      try {
        console.log("myDomainLismyDomainLis start");
        setListInit(true);
        const { data } = await getMyDomainList({ account, chain_id: chainId });
        const result: any = [];
        if (data?.owner_domains && data?.owner_domains?.length > 0) {
          data?.owner_domains.forEach((item: any) => {
            item.domainsOwner = true;
            result.push(item);
          });
        }
        if (data?.controller_domains && data?.controller_domains?.length > 0) {
          data?.controller_domains.forEach((item: any) => {
            let newDomains = result.filter((resultItem: any) => {
              if (resultItem.name === item.name) {
                resultItem.domainsController = true;
                return true;
              } else {
                return false;
              }
            });
            if (newDomains?.length > 0) {
            } else {
              item.domainsController = true;
              result.push(item);
            }
          });
        }
        const state = reduxStore.getState();
        const {
          domainManager: { localDomainList },
        } = state;

        if (!account) return;
        const useLocalDomainList = localDomainList[account] || [];

        if (useLocalDomainList && useLocalDomainList?.length > 0) {
          const nameString = result.map((item: any) => item.name);
          const newLocalArr: any = [];
          useLocalDomainList.forEach((item: any) => {
            if (!nameString.includes(item.name)) {
              //已经拉取的删除
              newLocalArr.push(item);
            }
          });
          if (useLocalDomainList?.length !== newLocalArr?.length) {
            const payload = { [account]: [...newLocalArr] };
            dispatch(updateLocalDomainList(payload));
          }
        }
        dispatch(updateDomainList({ domainList: result }));
      } catch {}

      return null;
    },
  });

  useEffect(() => {
    if (account && listInit) {
      refetch();
    } else {
      dispatch(updateDomainList({ domainList: [] }));
    }
  }, [account]);
  return null;
}
