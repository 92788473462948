import "@openblockhq/dappsdk";
import { initSensors } from "./sensors";
import ReactDOM from "react-dom/client";
import { App } from "./App";
import "@reach/dialog/styles.css";
import "react-tooltip/dist/react-tooltip.css";
import "./index.scss";

initSensors();

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(<App />);
