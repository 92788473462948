import { initializeConnector, Web3ReactHooks } from "@web3-react/core";
import { MetaMask } from "@web3-react/metamask";
import { OpenBlock } from "./openblock";
import { Connector } from "@web3-react/types";
import { InnerOpenBlock } from "./innerOpenblock";
export enum ConnectionType {
  METAMSDK = "METAMSDK",
  OPENBLOCK = "OPENBLOCK",
  INNEROPENBLOCK = "INNEROPENBLOCK",
}

export interface Connection {
  connector: Connector;
  hooks: Web3ReactHooks;
  type: ConnectionType;
}

function onError(error: Error) {
  console.debug(`web3-react error: ${error}`);
}

const [web3OpenBlock, web3OpenBlockHooks] = initializeConnector<OpenBlock>(
  (actions) => {
    console.log("initializeConnector web3Metamask", actions);
    return new OpenBlock({ actions, onError });
  }
);

export const openblockConnection: Connection = {
  connector: web3OpenBlock,
  hooks: web3OpenBlockHooks,
  type: ConnectionType.OPENBLOCK,
};

const [web3Metamask, web3MetamaskHooks] = initializeConnector<MetaMask>(
  (actions) => {
    console.log("initializeConnector web3Metamask", actions);
    return new MetaMask({ actions, onError });
  }
);

export const metamaskConnection: Connection = {
  connector: web3Metamask,
  hooks: web3MetamaskHooks,
  type: ConnectionType.METAMSDK,
};

const [innerWeb3OpenBlock, innerOpenBlockHooks] =
  initializeConnector<InnerOpenBlock>((actions: any) => {
    return new InnerOpenBlock({ actions, onError });
  });

export const innerOpenblockConnection: Connection = {
  connector: innerWeb3OpenBlock,
  hooks: innerOpenBlockHooks,
  type: ConnectionType.INNEROPENBLOCK,
};
