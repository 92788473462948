export const errorData = (err: any, t?: any) => {
  if (typeof err === "string") {
    return err;
  }
  if (err?.data?.message) {
    const checkValue = checkErro(err.data.message);
    if (checkValue && t) {
      return t(checkValue);
    }
    return err.data.message;
  }
  if (err?.message) {
    const checkValue = checkErro(err.message);
    if (checkValue && t) {
      if (checkValue === "userRejected") {
        return "";
      }
      return t(checkValue);
    }
    return err.message;
  }
  if (err) {
    return err;
  }
  return new Error("sendTransaction error");
};

const checkErro = (message: any) => {
  console.log("checkErro message", message);
  if (message.match(/insufficient funds for intrinsic transaction cost/i)) {
    return "CVaasqzogQmyMJriVJ";
  }
  if (message.match(/user rejected transaction/i)) {
    return "userRejected";
  }
  return null;
};
