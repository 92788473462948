import { useEffect, useMemo, useState } from "react";
import { nameAvailableStatus } from "@/helper/contract";

const useNameStatus = (
  name: any,
  account: any,
  chainId: any,
  userProof: any,
  viewNameInfo: any
) => {
  const [nameStatus, setNameStatus] = useState("");

  useEffect(() => {
    if (viewNameInfo?.searchName && viewNameInfo.searchName === name) {
      setNameStatus(viewNameInfo.searchStatus);
      return;
    }
    if (
      name &&
      account &&
      chainId &&
      userProof?.account &&
      account === userProof?.account
    ) {
      nameAvailableStatus(name, chainId, userProof).then((res: any) => {
        setNameStatus(res);
      });
    } else if (name && chainId) {
      nameAvailableStatus(name, chainId, {}).then((res: any) => {
        setNameStatus(res);
      });
    } else {
      setNameStatus("");
    }
  }, [name, account, chainId, userProof?.account, viewNameInfo]);

  return useMemo(() => {
    return nameStatus;
  }, [nameStatus]);
};

export default useNameStatus;
