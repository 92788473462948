import ScrollLogoIcon from "@/assets/svg/scroll-logo.svg";
import REGISTRARCONTROLLER from "@/abis/scrollAlpha/RegistrarController.json";
import RESERVATIONDOMAIN from "@/abis/scrollAlpha/ReservationDomain.json";
import BASEREGISTRARIMPLEMENTATION from "@/abis/scrollAlpha/BaseRegistrarImplementation.json";
import REVERSEREGISTRAR from "@/abis/scrollAlpha/ReverseRegistrar.json";
import PUBLICRESOLVER from "@/abis/scrollAlpha/PublicResolver.json";
import SCROLLREGISTRY from "@/abis/scrollAlpha/ScrollRegistry.json";

export default {
  534353: {
    chain: "Scroll Alpha Testnet",
    chainId: 534353,
    rpcURl: ["https://alpha-rpc.scroll.io/l2"],
    blockExplorerUrl: "https://blockscout.scroll.io/",
    blockExplorerTxUrl: "https://blockscout.scroll.io/tx/",
    blockExplorerAddrUrl: "https://blockscout.scroll.io/address/",
    logo: ScrollLogoIcon,
    REGISTRARCONTROLLER,
    RESERVATIONDOMAIN,
    BASEREGISTRARIMPLEMENTATION,
    REVERSEREGISTRAR,
    PUBLICRESOLVER,
    SCROLLREGISTRY,
  },
};
