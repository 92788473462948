import { createSlice } from "@reduxjs/toolkit";

export interface DomainManagerSliceState {
  domainName: any;
  domainList: any;
  localDomainList: any;
}
export const initialState: DomainManagerSliceState = {
  domainName: "",
  domainList: [],
  localDomainList: {},
};

const domainManagerSlice = createSlice({
  name: "domainManager",
  initialState,
  reducers: {
    updateDomainName(state, { payload: { name } }) {
      state.domainName = name;
    },
    updateDomainList(state, { payload: { domainList } }) {
      state.domainList = domainList;
    },
    updateLocalDomainList(state, { payload }) {
      const newLocalDomainList = { ...state.localDomainList, ...payload };
      state.localDomainList = newLocalDomainList;
    },
  },
});

export const { updateDomainName, updateDomainList, updateLocalDomainList } =
  domainManagerSlice.actions;

export default domainManagerSlice.reducer;
