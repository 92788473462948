import { ReactElement, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useWeb3React } from "@web3-react/core";
import { useAppSelector, useAppDispatch } from "@/state/hooks";
import { infoToastTip } from "@/components/ui/ToastTip";
import { DialogOverlay, DialogContent } from "@reach/dialog";
import Checkbox from "@/components/ui/Checkbox";
import { updateSetDomainTip } from "@/state/user/reducer";
import { setName } from "@/helper/contract";
import { txAwait } from "@/helper/utils/txAwait";
import reSetDomainName from "@/helper/contract/reSetDomainName";
import classnames from "classnames";
import dayjs from "dayjs";
import copyToClipboard from "copy-to-clipboard";
import { CHAIN_CONFIG } from "@/constants/chainInfo";
import { ReactComponent as CopyIcon } from "@/assets/svg/copy.svg";
import { ReactComponent as LinkIcon } from "@/assets/svg/link.svg";
import { ReactComponent as LogoSmallIcon } from "@/assets/svg/logo-small.svg";
import { ReactComponent as CloseIcon } from "@/assets/svg/close.svg";
import { MyDomainNoDataIcon } from "@/assets/svg-iconfont";
import { MyDomainNoOnlineIcon } from "@/assets/svg-iconfont";
import useTranslation from "@/hooks/useTranslation";
import { updataMyDomainList } from "@/state/domainManager/init";
import MirHidden from "@/components/ui/MirHidden";
const MyPages = (): ReactElement => {
  const [buyLoading, setBuyLoading] = useState(false);
  const [showDialogName, setShowDialogName] = useState("");
  const [isTips, setIsTips] = useState(false);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { provider, account, chainId } = useWeb3React();

  const { domainName, domainList, localDomainList } = useAppSelector(
    (state) => state.domainManager
  );

  useEffect(() => {
    if (account && chainId) {
      updataMyDomainList(account, chainId);
    }
  }, [account, chainId]);
  const setDomainTip = useAppSelector((state) => state.user.setDomainTip);

  const dateFormat: string = "YYYY-MM-DD HH:mm:ss";

  const close = (): void => {
    setShowDialogName("");
    if (isTips) {
      dispatch(updateSetDomainTip({ setDomainTip: true }));
    }
  };

  const setMyDomainFunc = (name: string): boolean | void => {
    if (name === domainName) {
      return false;
    }
    setShowDialogName(name);
    if (setDomainTip) {
      setTimeout(() => {
        confimbuy();
      }, 300);
    }
  };

  const goLinkFunc = (item: any): void => {
    const blockExplorerTxUrl = CHAIN_CONFIG[item.chain_id]?.blockExplorerTxUrl;
    if (!blockExplorerTxUrl) return;
    window.open(`${blockExplorerTxUrl}${item.last_hash}`, "_blank");
  };

  const goNamesDetailsFunc = (name: string): void => {
    navigate(`/name/${name}/view`);
  };
  const buyEnable = provider && account && chainId && !buyLoading;
  const confimbuy = async () => {
    if (buyEnable && showDialogName) {
      setBuyLoading(true);
      try {
        const tx: any = await setName(
          provider,
          account,
          chainId,
          showDialogName,
          t
        );
        const result = await txAwait(provider, tx.hash);
        if (result.status === 1) {
          infoToastTip({
            status: "success",
            title: t("rSRnTfRpXgCYZglga"),
            toastId: `priceUpdate${new Date().valueOf()}`,
          });
          setBuyLoading(false);
          close();
          reSetDomainName(account, chainId);
          return;
        }
        setBuyLoading(false);
        infoToastTip({
          status: "fail",
          title: t("TrjQofdvqwaKhkPiiCW"),
          toastId: `priceUpdate${new Date().valueOf()}`,
        });
      } catch (err: any) {
        setBuyLoading(false);
        const showErr =
          err && typeof err === "string" ? err : t("TrjQofdvqwaKhkPiiCW");
        infoToastTip({
          status: "fail",
          title: showErr,
          toastId: `setName${new Date().valueOf()}`,
        });
      }
    }
  };

  const showDomainList = useMemo(() => {
    const uselocalDomainList =
      account && localDomainList[account] && localDomainList[account]?.length
        ? localDomainList[account].filter(
            (item: any) => item.chain_id === chainId
          )
        : [];
    console.log("uselocalDomainListuselocalDomainList", uselocalDomainList);
    const usedomainList =
      domainList && domainList?.length
        ? domainList.filter((item: any) => item.chain_id === chainId)
        : [];
    let result = [...uselocalDomainList, ...usedomainList];
    result.sort(function (a, b) {
      return b.created_at > a.created_at ? 1 : -1;
    });
    return result;
  }, [domainList, localDomainList, account]);

  const clickCopy = (copyValue: string) => {
    copyToClipboard(copyValue);
    infoToastTip({
      status: "success",
      title: t("nBMZbjmYxYoLgLGLz"),
      toastId: `setUpdate${new Date().valueOf()}`,
    });
  };

  console.log("showDomainListshowDomainList", showDomainList);
  return (
    <div className="page-my-box">
      <div className="page-my-title">{t("QevesDuuBJEePdMdL")}</div>
      {navigator.onLine ? (
        <>
          {showDomainList && showDomainList?.length ? (
            <div className="page-my-data">
              {showDomainList.map((cur: any) => {
                const currentName = `${cur.name}.scroll`;
                const showBtnLoaing =
                  buyLoading && showDialogName === currentName;
                return (
                  <div className="page-my-data-item" key={cur.name}>
                    <div className="page-my-data-item-left">
                      <div className="page-my-data-item-left-domains">
                        <div className="page-my-data-item-left-logo">
                          <LogoSmallIcon />
                        </div>
                        <div className="page-my-data-item-left-name">
                          {currentName}
                        </div>
                        <div
                          className="page-my-data-item-left-copy"
                          onClick={() => {
                            clickCopy(currentName);
                          }}
                        >
                          <CopyIcon />
                        </div>
                      </div>
                      {cur?.domainsOwner && currentName === domainName ? (
                        <div
                          onClick={() => setMyDomainFunc(currentName)}
                          className={classnames(
                            "page-my-data-item-left-set page-my-data-item-left-setting"
                          )}
                        >
                          {t("AOaXnzwemFPXhuujmv")}
                        </div>
                      ) : null}
                      {cur?.domainsOwner && currentName !== domainName ? (
                        <div
                          onClick={() => setMyDomainFunc(currentName)}
                          className={classnames("page-my-data-item-left-set", {
                            "btn-disable":
                              !buyEnable && showDialogName === currentName,
                            "btn-loading": showBtnLoaing,
                          })}
                        >
                          {showBtnLoaing ? (
                            <div className="btn-loading-icon" />
                          ) : null}
                          {showBtnLoaing
                            ? t("ynwUyiaHWZrtspezRygd")
                            : t("urKyEDDqdhoIeoXg")}
                        </div>
                      ) : null}
                    </div>
                    <MirHidden smUp>
                      <div className="page-my-data-item-date">
                        <div className="page-my-data-item-right-label">
                          {t("PiWcYFfCMeaPffHSzpH")}
                        </div>
                        <div className="page-my-data-item-right-time">
                          {dayjs(cur.expired_at).format(dateFormat)}
                        </div>
                      </div>
                    </MirHidden>
                    <div className="page-my-data-item-right">
                      <MirHidden smDown>
                        <div className="page-my-data-item-right-label">
                          {t("PiWcYFfCMeaPffHSzpH")}
                        </div>
                        <div className="page-my-data-item-right-time">
                          {dayjs(cur.expired_at).format(dateFormat)}
                        </div>
                      </MirHidden>
                      <div
                        className="page-my-data-item-right-link"
                        onClick={() => goLinkFunc(cur)}
                      >
                        <LinkIcon />
                      </div>
                      <div
                        className="page-my-data-item-right-detail"
                        onClick={() => goNamesDetailsFunc(cur.name)}
                      >
                        {t("CHirEDWDKvufbkUlRCoS")}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <div className="page-my-no-data">
              <div>
                <MyDomainNoDataIcon />
              </div>
              <p>{t("CjvwyjHpYOFrdzsWf")}</p>
            </div>
          )}
        </>
      ) : (
        <div className="page-my-not-online">
          <div>
            <MyDomainNoOnlineIcon />
          </div>
          <p>{t("YRdVoqyWhpdAqYcilT")}</p>
          <div onClick={() => window.location.reload()}>
            {t("EXGSDHbZnGiHgRUFAQ")}
          </div>
        </div>
      )}

      <DialogOverlay
        isOpen={showDialogName && !setDomainTip ? true : false}
        onDismiss={close}
        dangerouslyBypassFocusLock
      >
        <DialogContent className="set-domain-modal">
          <div className="set-domain-modal-content">
            <div className="set-domain-modal-close-icon">
              <div onClick={close}>
                <CloseIcon />
              </div>
            </div>
            <div className="set-domain-modal-title">
              {t("urKyEDDqdhoIeoXg")}
            </div>
            <div className="set-domain-modal-desc">
              {t("gqQUuZClvoaFtiqMq", { name: showDialogName })}
            </div>
            <div className="set-domain-modal-not-tips">
              <Checkbox
                labelText={t("EqguwbVzLBfIPFwbeZe")}
                checked={isTips}
                onChange={() => setIsTips(!isTips)}
              />
            </div>
            <div
              className={classnames("set-domain-modal-submit", {
                "btn-disable": !buyEnable,
                "btn-loading": buyLoading,
              })}
              onClick={confimbuy}
            >
              {buyLoading ? <div className="btn-loading-icon" /> : null}
              {buyLoading ? t("ynwUyiaHWZrtspezRygd") : t("eGVsRveYqUQSuhtnni")}
            </div>
          </div>
        </DialogContent>
      </DialogOverlay>
    </div>
  );
};

export default MyPages;
