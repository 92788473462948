import { useEffect, useMemo, useState } from "react";
import { getRentPrice } from "@/helper/contract";
import BigNumber from "bignumber.js";
import { getNativeBalanceFromProvider } from "@/helper/utils/getBalances";

const useRentPrice = ({ name, years, account, chainId }: any) => {
  const [price, setPrice] = useState("0");
  const [balance, setBalance] = useState("");

  const getBalance = (account: string, chainId: any) => {
    let retryTime = 0;
    const fn = () => {
      getNativeBalanceFromProvider(account, chainId)
        .then((res) => {
          if (res) {
            setBalance(res.toString());
          }
        })
        .catch((error) => {
          console.warn("fetchBalanceNativeCallback 错误", error, retryTime);
          if (retryTime < 3) {
            retryTime++;
            setTimeout(() => {
              fn();
            }, 1000);
          }
        });
    };
    fn();
  };

  useEffect(() => {
    console.log("resresres aas", name, chainId);
    if (name && chainId) {
      getRentPrice(name, chainId).then((res: any) => {
        console.log("resresres", name, chainId, res);
        setPrice(res);
      });
    } else {
      setPrice("0");
    }
  }, [name, chainId]);

  useEffect(() => {
    if (account && chainId) {
      getBalance(account, chainId);
    } else {
      setBalance("");
    }
  }, [account, chainId]);

  return useMemo(() => {
    const result = {
      allFee: "0",
      oneYearFee: "0",
      balance: "",
    };
    if (price && years) {
      result.allFee = new BigNumber(price).multipliedBy(years).toFixed();
      result.oneYearFee = new BigNumber(price).toFixed();
    }
    if (balance) {
      result.balance = balance;
    }

    return result;
  }, [price, years, balance]);
};

export default useRentPrice;
