import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch } from "@/state/hooks";
import { updateInviter } from "@/state/user/reducer";
import SearchInput from "@/components/ui/SearchInput";
import { ReactComponent as SearchBarIcon } from "@/assets/svg/search-bar.svg";
import { SearchBarYesIcon, SearchBarNotIcon } from "@/assets/svg-iconfont";
import { homePageViewEvent } from "@/helper/utils/sensors";
import useTranslation from "@/hooks/useTranslation";

const SearchPage = () => {
  const { address } = useParams();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    homePageViewEvent();
  }, []);

  useEffect(() => {
    if (address) {
      dispatch(updateInviter({ inviter: address }));
    }
  }, [address]);

  return (
    <div className="page-search-box">
      <div className="page-search-content">
        <div className="page-search-title">{t("uFkYjYLxvLcqDjkvS")}</div>
        <div className="page-search-center">
          <SearchInput size={"extraLarge"} />
        </div>
        <div className="page-search-bar">
          <div className="page-search-bar-title">
            <SearchBarIcon />
            <span>{t("rFZkKfQihYBPXZwodmT")}</span>
          </div>
          <div className="page-search-bar-desc">
            <div>
              <SearchBarYesIcon />
              <span>{t("uGUHUSxjzuVRjnFxYMU")}</span>
            </div>
            <div>
              {/* <SearchBarYesIcon /> */}
              <SearchBarNotIcon />
              <span>{t("TjiSDwrACRWkyhwfdVip")}</span>
            </div>
            <div>
              <SearchBarNotIcon />
              <span>{t("MhwAGgHEQrZbsaSQBq")}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default SearchPage;
