import { useCallback, useEffect, useState } from "react";
import { useMemo } from "react";
import { debounce } from "lodash";
import { useNavigate } from "react-router-dom";
import classnames from "classnames";
import { nameAvailableStatus } from "@/helper/contract";
import { ReactComponent as ArrowRight } from "@/assets/svg/arrow-right.svg";
import { setHistorySearch } from "@/state/user/init";
import { statusConstants, batch, batchDomainLength } from "@/constants";

// const statusConstants: any = {
//   loading: "loading",
//   shortErr: "EePllyAaAoZPlkvaap",
//   available: "OblKUfdvEBvBZtbXC",
//   registered: "MnDuyNiIGkWSIxowRrTV",
//   notsale: "ELgPmNQPkrYbkTFzZUo",
// };

function promiseTimeout(promise: any, delay: number) {
  let timeout = new Promise(function (reslove, reject) {
    setTimeout(function () {
      reject("超时啦~");
    }, delay);
  });
  return Promise.race([timeout, promise]);
}

const SearchItem = ({
  inputValue,
  setInputVal,
  userProof,
  setOpenResult,
  chainId,
  t,
}: any) => {
  const navigate = useNavigate();
  const [status, setStatus] = useState("");
  const [timeout, setTimeOut] = useState(false);

  const queryNames = (
    name: string,
    userProofParams: any,
    chainIdParams: number
  ) => {
    setStatus("loading");
    promiseTimeout(
      nameAvailableStatus(name, chainIdParams, userProofParams),
      20000
    )
      .then((res: string) => {
        setStatus(res);
        setTimeOut(false);
      })
      .catch(() => {
        setStatus("");
        setTimeOut(true);
      });
  };
  const debouncedSearchTokenFun = useCallback(
    debounce(
      (name: string, userProofParams: any, chainIdParams: number) =>
        queryNames(name, userProofParams, chainIdParams),
      1000
    ),
    []
  );
  const showResult = useMemo(() => {
    if (inputValue) {
      return [`${inputValue}.scroll`];
    }
    return [];
  }, [inputValue]);
  let re = /^[0-9a-zA-Z]*$/;
  useEffect(() => {
    const useBatchDomainLength = batchDomainLength[batch] || false;
    if (inputValue) {
      if (inputValue?.length < 2) {
        setStatus("shortErr");
      } else if (!re.test(inputValue)) {
        setStatus("invalid");
      } else if (
        useBatchDomainLength &&
        inputValue?.length < useBatchDomainLength
      ) {
        setStatus("notsale");
      } else {
        setStatus("");
        debouncedSearchTokenFun(inputValue, userProof, chainId);
      }
    } else {
      setStatus("");
    }
  }, [inputValue, userProof]);

  const goToDetail = () => {
    if (timeout) {
      research();
      return;
    }
    if (status === "available" || status === "release") {
      setInputVal("");
      setOpenResult("");
      setHistorySearch(inputValue);
      navigate(`/name/${inputValue}/register`, {
        state: { searchName: inputValue, searchStatus: status },
      });
      return;
    }
    if (status === "registered") {
      setInputVal("");
      setOpenResult("");
      setHistorySearch(inputValue);
      navigate(`/name/${inputValue}/view`, {
        state: { searchName: inputValue, searchStatus: status },
      });
    }
  };

  const research = () => {
    queryNames(inputValue, userProof, chainId);
  };
  return (
    <div className="result-item" onClick={goToDetail}>
      <div className="result-item-label">{showResult}</div>
      <div
        className={classnames("result-item-status", {
          "result-item-status-loading": !status || status === "loading",
        })}
        data-status={status}
      >
        {status === "loading" && (
          <div className="result-item-status-loading-icon" />
        )}
        {status && status !== "loading" ? (
          <span
            className={classnames("", {
              success: status === "available" || status === "release",
              error:
                status === "notsale" ||
                status === "registered" ||
                status === "shortErr" ||
                status === "invalid",
            })}
          >
            {statusConstants[status] ? t(statusConstants[status]) : ""}
          </span>
        ) : null}
        {status === "available" ||
        status === "release" ||
        status === "registered" ? (
          <i>
            <ArrowRight />
          </i>
        ) : null}

        {!status && timeout ? (
          <>
            <span className="timeout">{t("iFSKnKvhCzkYKfpDRr")}</span>
            <span className="success">{t("rLiQVSzBeOmAEvMfc")}</span>
          </>
        ) : null}
      </div>
    </div>
  );
};

export default SearchItem;
