import ScrollLogoIcon from "@/assets/svg/scroll-logo.svg";
import REGISTRARCONTROLLER from "@/abis/scrollSepolia/RegistrarController.json";
import RESERVATIONDOMAIN from "@/abis/scrollSepolia/ReservationDomain.json";
import BASEREGISTRARIMPLEMENTATION from "@/abis/scrollSepolia/BaseRegistrarImplementation.json";
import REVERSEREGISTRAR from "@/abis/scrollSepolia/ReverseRegistrar.json";
import PUBLICRESOLVER from "@/abis/scrollSepolia/PublicResolver.json";
import SCROLLREGISTRY from "@/abis/scrollSepolia/ScrollRegistry.json";

export default {
  534351: {
    chain: "Scroll Sepolia Testnet",
    chainId: 534351,
    rpcURl: ["https://scroll-sepolia.blockpi.network/v1/rpc/public"],
    blockExplorerUrl: "https://sepolia-blockscout.scroll.io/",
    blockExplorerTxUrl: "https://sepolia-blockscout.scroll.io/tx/",
    blockExplorerAddrUrl: "https://sepolia-blockscout.scroll.io/address/",
    logo: ScrollLogoIcon,
    REGISTRARCONTROLLER,
    RESERVATIONDOMAIN,
    BASEREGISTRARIMPLEMENTATION,
    REVERSEREGISTRAR,
    PUBLICRESOLVER,
    SCROLLREGISTRY,
  },
};
