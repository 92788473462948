import { useNavigate } from "react-router-dom";
import { RegisterSuccessIcon } from "@/assets/svg-iconfont";
import useTranslation from "@/hooks/useTranslation";

const RegisterSuccess = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const goHomeFunc = (): void => {
    navigate("/");
  };
  const goMyDomainFunc = (): void => {
    navigate("/my");
  };

  return (
    <div className="page-register-success">
      <div className="page-register-success-icon">
        <RegisterSuccessIcon />
      </div>
      <div className="page-register-success-desc">
        {t("OVbKjTzfsaEArDldkG")}
      </div>
      <div className="page-register-success-btn">
        <div className="page-register-home-btn" onClick={goHomeFunc}>
          {t("mOoNIHZakGOsWFDBFjV")}
        </div>
        <div className="page-register-my-btn" onClick={goMyDomainFunc}>
          {t("GwjeIwgBPXxaXHKvO")}
        </div>
      </div>
    </div>
  );
};

export default RegisterSuccess;
