import React, { ReactElement, useState } from "react";
import { DialogOverlay, DialogContent } from "@reach/dialog";
import { ReactComponent as CloseIcon } from "@/assets/svg/close.svg";
import { useWeb3React } from "@web3-react/core";
import { reclaim, transferFrom } from "@/helper/contract";
import { txAwait } from "@/helper/utils/txAwait";
import classNames from "classnames";
import { infoToastTip } from "@/components/ui/ToastTip";
import { isAddress } from "@/utils";
import {
  updataControllerDomainName,
  updataOwnerDomainName,
} from "@/state/domainManager/init";
import useTranslation from "@/hooks/useTranslation";

interface NameSetDialogInterface {
  visible: boolean;
  registrant: any;
  controller: any;
  name: string;
  onCancel?(event: React.MouseEvent | React.KeyboardEvent): void;
  onOk: (type: string, val: string) => void;
  type: string;
  defaultOwner: boolean;
}

const NameSetDialog = (props: NameSetDialogInterface): ReactElement => {
  const { visible, onCancel, type, name, onOk, defaultOwner, controller } =
    props;

  const [buyLoading, setBuyLoading] = useState(false);
  const [nameSetAddress, setNameSetAddress] = useState("");

  const { account, provider, chainId } = useWeb3React();
  const { t } = useTranslation();

  const changeNameSetAddress = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const val = e.target.value ? e.target.value.trim() : "";
    setNameSetAddress(val);
  };

  const buyEnable = provider && account && chainId && nameSetAddress && name;

  const transfer = async () => {
    if (buyEnable) {
      try {
        setBuyLoading(true);
        if (!isAddress(nameSetAddress)) {
          infoToastTip({
            status: "fail",
            title: t("miLTowbNjQcVjuVWa"),
            toastId: `setUpdate${new Date().valueOf()}`,
          });
          setBuyLoading(false);
          return;
        }
        if (
          account.toLocaleLowerCase() === nameSetAddress.toLocaleLowerCase()
        ) {
          infoToastTip({
            status: "fail",
            title: t("pjFyBXZDZIuwqfnSwSWE"),
            toastId: `setUpdate${new Date().valueOf()}`,
          });
          setBuyLoading(false);
          return;
        }
        const tx = await transferFrom(
          provider,
          account,
          chainId,
          nameSetAddress,
          name,
          t
        );
        const result = await txAwait(provider, tx.hash);
        console.log("result result", result);
        if (result.status === 1) {
          onOk(type, nameSetAddress);
          const newDomain = {
            owner: nameSetAddress,
            name: name,
            last_hash: tx.hash,
            domainsOwner: false,
          };
          updataOwnerDomainName(newDomain);
        } else {
          infoToastTip({
            status: "fail",
            title: t("TrjQofdvqwaKhkPiiCW"),
            toastId: `setUpdate${new Date().valueOf()}`,
          });
        }
        setBuyLoading(false);
      } catch (err: any) {
        const showErr =
          err && typeof err === "string" ? err : t("TrjQofdvqwaKhkPiiCW");
        infoToastTip({
          status: "fail",
          title: showErr,
          toastId: `setUpdate${new Date().valueOf()}`,
        });
        setBuyLoading(false);
      }
    }
  };

  const pagereclaim = async () => {
    if (buyEnable) {
      try {
        setBuyLoading(true);
        if (!isAddress(nameSetAddress)) {
          infoToastTip({
            status: "fail",
            title: t("miLTowbNjQcVjuVWa"),
            toastId: `setUpdate${new Date().valueOf()}`,
          });
          setBuyLoading(false);
          return;
        }
        if (
          controller &&
          controller.toLocaleLowerCase() === nameSetAddress.toLocaleLowerCase()
        ) {
          infoToastTip({
            status: "fail",
            title: t("pjFyBXZDZIuwqfnSwSWE"),
            toastId: `setUpdate${new Date().valueOf()}`,
          });
          setBuyLoading(false);
          return;
        }
        const tx = await reclaim(
          provider,
          account,
          chainId,
          nameSetAddress,
          name,
          t
        );
        const result = await txAwait(provider, tx.hash);
        console.log("result result", result);
        if (result.status === 1) {
          onOk(type, nameSetAddress);
          const newDomain = {
            name: name,
            domainsController: account === nameSetAddress ? true : false,
            last_hash: tx.hash,
            controller: nameSetAddress,
          };
          updataControllerDomainName(newDomain);
        } else {
          infoToastTip({
            status: "fail",
            title: t("TrjQofdvqwaKhkPiiCW"),
            toastId: `setUpdate${new Date().valueOf()}`,
          });
        }
        setBuyLoading(false);
      } catch (err: any) {
        setBuyLoading(false);
        const showErr =
          err && typeof err === "string" ? err : t("TrjQofdvqwaKhkPiiCW");
        infoToastTip({
          status: "fail",
          title: showErr,
          toastId: `setUpdate${new Date().valueOf()}`,
        });
      }
    }
  };

  const confirmSet = () => {
    if (type === "transfer") {
      transfer();
    } else {
      pagereclaim();
    }
  };

  return (
    <DialogOverlay isOpen={visible} dangerouslyBypassFocusLock>
      <DialogContent className="name-set-modal">
        <div className="name-set-content">
          <div className="name-set-modal-close-icon">
            {!buyLoading ? (
              <div onClick={onCancel}>
                <CloseIcon />
              </div>
            ) : null}
          </div>
          <div className="name-set-modal-title">
            {type === "transfer"
              ? t("hCCIxzZgmvVUHEadUj")
              : t("MaPGwoWxkGybeCdXRc")}
          </div>
          <div className="name-set-modal-input">
            <input
              value={nameSetAddress}
              onChange={changeNameSetAddress}
              type="text"
              placeholder={t("QfYQwLLHsOWkReYBdti")}
            />
          </div>
          {defaultOwner && type === "set" ? (
            <div className="name-set-default-address">
              {t("EjPWnFjpyDUIoGqEHE")}
            </div>
          ) : null}
          <div className="name-set-modal-submit-btn">
            {!buyLoading && (
              <div
                className="btn-div name-set-modal-cancel-btn"
                onClick={onCancel}
              >
                {t("WosebhCDqziYYnRIxb")}
              </div>
            )}
            <div
              className={classNames("btn-div name-set-modal-confirm-btn", {
                "btn-disable": !buyEnable,
                "btn-loading": buyLoading,
                "name-set-modal-confirm-btn-loding": buyLoading,
              })}
              onClick={confirmSet}
            >
              {buyLoading ? <div className="btn-loading-icon" /> : null}
              {buyLoading ? t("IMIIYOBqhHiVbumAsLM") : t("jzkrOgpjJkxvPGxYh")}
            </div>
          </div>
        </div>
      </DialogContent>
    </DialogOverlay>
  );
};

export default NameSetDialog;
