import { Fragment, JsonFragment } from "@ethersproject/abi";
import BigNumber from "bignumber.js";
import { Token, TokenAmount } from "@/constants/interface";
import { multicallAddresses } from "@/constants/multicall";
import { isZeroAddress } from "../../utils/index";
import { fetchDataUsingMulticall, MultiCallData } from "./multicall";
import { getRpcProvider } from "./rpcProvider";
import { FallbackProvider, JsonRpcProvider } from "@ethersproject/providers";
import { ethers } from "ethers";

type Balance = {
  amount: BigNumber;
  blockNumber: number;
};

const balanceAbi = [
  {
    constant: true,
    inputs: [{ name: "who", type: "address" }],
    name: "balanceOf",
    outputs: [{ name: "", type: "uint256" }],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
  {
    constant: true,
    inputs: [{ name: "addr", type: "address" }],
    name: "getEthBalance",
    outputs: [{ name: "balance", type: "uint256" }],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
];

export const getBalance = async (
  walletAddress: string,
  token: Token,
  provider?: JsonRpcProvider
): Promise<TokenAmount[]> => {
  return getBalances(walletAddress, [token], provider);
};
const getBalances = async (
  walletAddress: string,
  tokens: Token[],
  provider?: JsonRpcProvider
): Promise<TokenAmount[]> => {
  if (tokens.length === 0) {
    return [];
  }
  const { chain_id } = tokens[0];
  tokens.forEach((token) => {
    if (token.chain_id !== chain_id) {
      // eslint-disable-next-line no-console
      console.warn(`Requested tokens have to be on the same chain.`);
      return [];
    }
  });
  if (multicallAddresses[parseInt(chain_id)] && tokens.length > 1) {
    return getBalancesFromProviderUsingMulticall(
      walletAddress,
      tokens,
      provider
    );
  } else {
    return getBalancesFromProvider(walletAddress, tokens, provider);
  }
};

const getBalancesFromProviderUsingMulticall = async (
  walletAddress: string,
  tokens: Token[],
  provider?: JsonRpcProvider
): Promise<TokenAmount[]> => {
  // Configuration
  const { chain_id } = tokens[0];
  const multicallAddress = await multicallAddresses[parseInt(chain_id)];
  if (!multicallAddress) {
    throw new Error("No multicallAddress found for the given chain.");
  }
  return executeMulticall(
    walletAddress,
    tokens,
    multicallAddress,
    parseInt(chain_id),
    provider
  );
};

const executeMulticall = async (
  walletAddress: string,
  tokens: Token[],
  multicallAddress: string,
  chainId: number,
  provider?: JsonRpcProvider
): Promise<Array<TokenAmount>> => {
  // Collect calls we want to make
  const calls: Array<MultiCallData> = [];
  tokens.forEach((token) => {
    if (isZeroAddress(token.address)) {
      calls.push({
        address: multicallAddress,
        name: "getEthBalance",
        params: [walletAddress],
      });
    } else {
      calls.push({
        address: token.address,
        name: "balanceOf",
        params: [walletAddress],
      });
    }
  });
  const res = await fetchViaMulticall(
    calls,
    balanceAbi,
    chainId,
    multicallAddress,
    provider
  );

  if (!res.length) {
    return [];
  }
  return tokens.map((token, i: number) => {
    const amount = new BigNumber(res[i].amount.toString() || "0")
      .shiftedBy(-token.decimals)
      .toFixed();
    return {
      ...token,
      amount: amount || "0",
      blockNumber: res[i].blockNumber,
    };
  });
};

const fetchViaMulticall = async (
  calls: Array<MultiCallData>,
  abi: ReadonlyArray<Fragment | JsonFragment | string>,
  chainId: number,
  multicallAddress: string,
  provider?: JsonRpcProvider
): Promise<Balance[]> => {
  const result = await fetchDataUsingMulticall(
    calls,
    abi,
    chainId,
    multicallAddress,
    true,
    provider
  );
  return result.map(({ data, blockNumber }) => ({
    amount: data ? (data as BigNumber) : new BigNumber(0),
    blockNumber,
  }));
};

const getBalancesFromProvider = async (
  walletAddress: string,
  tokens: Token[],
  provider?: JsonRpcProvider
): Promise<TokenAmount[]> => {
  const chainId = tokens[0].chain_id;
  const rpc: any = provider
    ? provider
    : await getRpcProvider(parseInt(chainId));
  console.log("getBalancesFromProvider provider", provider);
  const tokenAmountPromises: Promise<TokenAmount>[] = tokens.map(
    async (token): Promise<TokenAmount> => {
      let amount = "0";
      let blockNumber;

      try {
        const balance = await getBalanceFromProvider(
          walletAddress,
          token.address,
          parseInt(chainId),
          rpc
        );
        amount = new BigNumber(balance.amount.toString())
          .shiftedBy(-token.decimals)
          .toFixed();
        // .toFixed(6, BigNumber.ROUND_DOWN);
        blockNumber = balance.blockNumber;
      } catch (e) {
        // eslint-disable-next-line no-console
        console.warn(e);
      }
      return {
        ...token,
        amount,
        blockNumber,
      };
    }
  );
  return Promise.all(tokenAmountPromises);
};

const getBalanceFromProvider = async (
  walletAddress: string,
  assetId: string,
  chainId: number,
  provider: FallbackProvider
): Promise<Balance> => {
  const blockNumber = await provider.getBlockNumber();

  let balance;
  if (isZeroAddress(assetId)) {
    balance = await provider.getBalance(walletAddress);
  } else {
    const contract = new ethers.Contract(
      assetId,
      ["function balanceOf(address owner) view returns (uint256)"],
      provider
    );
    balance = await contract.balanceOf(walletAddress);
  }
  return {
    amount: balance,
    blockNumber,
  };
};

export const getCurrentBlockNumber = async (
  chainId: number
): Promise<number> => {
  const rpc = await getRpcProvider(chainId);
  return rpc.getBlockNumber();
};

export default getBalances;

export const getTokenBalanceFromProvider = async (
  walletAddress: string,
  token: any,
  paramsPprovider?: JsonRpcProvider
): Promise<any> => {
  const assetId = token.address;
  const chainId = parseInt(token.chain_id);
  const provider = paramsPprovider
    ? paramsPprovider
    : await getRpcProvider(chainId);
  return new Promise(async (resolve, reject) => {
    try {
      let balance;
      if (isZeroAddress(assetId)) {
        balance = await provider.getBalance(walletAddress);
      } else {
        const contract = new ethers.Contract(
          assetId,
          ["function balanceOf(address owner) view returns (uint256)"],
          provider
        );
        balance = await contract.balanceOf(walletAddress);
      }
      const amount = new BigNumber(balance.toString())
        .shiftedBy(-token.decimals)
        .toFixed();
      resolve({ amount, provider });
    } catch (err) {
      reject({ err, provider });
    }
  });
};

export const getNativeBalanceFromProvider = async (
  walletAddress: string,
  chain_id: any
): Promise<any> => {
  const provider = await getRpcProvider(chain_id);
  return new Promise(async (resolve, reject) => {
    try {
      let balance;
      balance = await provider.getBalance(walletAddress);
      resolve(balance);
    } catch (err) {
      reject(err);
    }
  });
};
