import type { JsonRpcProvider } from "@ethersproject/providers";

function promiseTimeout(promise: any, delay: number) {
  let timeout = new Promise(function (reslove, reject) {
    setTimeout(function () {
      reject("超时啦~");
    }, delay);
  });
  return Promise.race([timeout, promise]);
}

export const txAwait = (
  provider: JsonRpcProvider,
  txhash: string
): Promise<any> => {
  return new Promise(async (resolve, reject) => {
    let retryTime = 0;
    let fn = () => {
      console.log("Tx txAwait start", txhash);
      promiseTimeout(provider.waitForTransaction(txhash), 60000)
        .then((res) => {
          resolve(res);
          console.log("Tx txAwait result", res);
        })
        .catch((err) => {
          console.error("Tx txAwait error", err);
          if (retryTime < 3) {
            retryTime++;
            setTimeout(() => {
              fn();
            }, 1000);
          }
        });
    };
    fn();
  });
};
