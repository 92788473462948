import { useState, useMemo } from "react";
import BigNumber from "bignumber.js";
import type { JsonRpcProvider } from "@ethersproject/providers";
import { Interface } from "@ethersproject/abi";
import { yearsToSeconds } from "@/helper/utils";
import { CHAIN_CONFIG } from "@/constants/chainInfo";

const useNameReNew = (
  provider: JsonRpcProvider | undefined,
  account: string | undefined,
  name: string,
  durationYears: any,
  allFee: any,
  visible: any,
  chainId: any
) => {
  const [callData, setCallData] = useState<any>("");
  const [gasPrice, setGasPrice] = useState<any>(null);
  const [gasLimit, setGasLimit] = useState<any>(null);
  const [loading, setLoading] = useState(false);

  const REGISTRARCONTROLLER = CHAIN_CONFIG[chainId]?.REGISTRARCONTROLLER || {};

  useMemo(async () => {
    if (provider && account && name && durationYears && allFee && visible) {
      setLoading(true);
      try {
        const functionName: string = "renew";
        const iface: Interface = new Interface(REGISTRARCONTROLLER.abi);
        const duration = yearsToSeconds(durationYears);
        const getCallData = iface.encodeFunctionData(functionName, [
          name,
          duration,
        ]);
        const getGasPrice: any = (await provider.getGasPrice())
          .mul(105)
          .div(100);
        const getGasLimit = "100000";
        setCallData(getCallData);
        setGasPrice(getGasPrice);
        setGasLimit(getGasLimit);
        setLoading(false);
      } catch (err: any) {
        console.error("errerrerrerrerr xx", err);
        setLoading(false);
        setCallData({ error: "error" });
        // throw new Error(err);
      }
    }
  }, [provider, account, name, durationYears, allFee, visible]);

  return useMemo(() => {
    if (callData && gasPrice && gasLimit) {
      return {
        callData,
        contractAddress: REGISTRARCONTROLLER.address,
        gasPrice: gasPrice.toString(),
        gasLimit: gasLimit.toString(),
        loading,
      };
    }
    return {
      callData: { error: "error" },
      contractAddress: REGISTRARCONTROLLER.address,
      gasPrice: new BigNumber("0"),
      gasLimit: new BigNumber("0"),
      loading,
    };
  }, [gasLimit, gasPrice, callData, loading]);
};

export default useNameReNew;
