import { isAddress } from "@/utils";
import { AddressZero } from "@ethersproject/constants";
import { Contract } from "@ethersproject/contracts";
import type { JsonRpcProvider, JsonRpcSigner } from "@ethersproject/providers";
import { getRpcProvider } from "../utils/rpcProvider";
import { BigNumber, utils as ethersUtils } from "ethers";
import { yearsToSeconds } from "../utils";
import BigNumberJs from "bignumber.js";
import { CHAIN_CONFIG } from "@/constants/chainInfo";
import { errorData } from "./utils";
import { initChainId } from "@/constants";

// account is not optional
function getSigner(provider: JsonRpcProvider, account: string): JsonRpcSigner {
  return provider.getSigner(account).connectUnchecked();
}

// account is optional
function getProviderOrSigner(
  provider: JsonRpcProvider,
  account?: string
): JsonRpcProvider | JsonRpcSigner {
  return account ? getSigner(provider, account) : provider;
}

// account is optional
export function getContract(
  address: string,
  ABI: any,
  provider: JsonRpcProvider,
  account?: string
): Contract {
  if (!isAddress(address) || address === AddressZero) {
    throw Error(`Invalid 'address' parameter '${address}'.`);
  }

  return new Contract(
    address,
    ABI,
    getProviderOrSigner(provider, account) as any
  );
}

// 查看白名单用户是否已经购买了域名
export const reservationDomainRegistered = async (
  account: string,
  chainId: any
): Promise<boolean> => {
  const provider = await getRpcProvider(chainId);
  const RESERVATIONDOMAIN = CHAIN_CONFIG[chainId].RESERVATIONDOMAIN;
  const contract = new Contract(
    RESERVATIONDOMAIN.address,
    RESERVATIONDOMAIN.abi,
    provider
  );
  const isRegistered = await contract.registrant(account);
  return isRegistered;
};

// type NameStatus = "registered" | "available" | "notsale" | "release";
//查询域名状态
export const nameAvailableStatus = async (
  name: string,
  chainId: number,
  userProof: any
): Promise<any> => {
  if (!chainId) return;
  const provider = await getRpcProvider(chainId);
  const REGISTRARCONTROLLER = CHAIN_CONFIG[chainId].REGISTRARCONTROLLER;
  const contract = new Contract(
    REGISTRARCONTROLLER.address,
    REGISTRARCONTROLLER.abi,
    provider
  );

  // const REGISTERED = 1  已注册
  // const NOT_RELEASE = 2 未释放
  // const RELEASE = 3     白名单释放
  // const UNAVAILABLE = 4 未释放
  // const AVAILABLE = 5 可注册

  const availableStatus = await contract.available(name);
  console.log(
    "availableStatus",
    name,
    chainId,
    REGISTRARCONTROLLER.address,
    availableStatus,
    userProof
  );
  if (availableStatus === 1) {
    return "registered";
  }
  if (availableStatus === 5) {
    return "available";
  }
  if (availableStatus === 2 || availableStatus === 4) {
    return "notsale";
  }
  if (availableStatus === 3 && userProof?.is_white_account) {
    const isRegistered = await reservationDomainRegistered(
      userProof.account,
      chainId
    );
    if (isRegistered) {
      return "notsale";
    }
    return "release";
  }
  return "notsale";
};

//查询域名详情
export const nameDetail = async (name: string, chainId: any) => {
  const provider = await getRpcProvider(chainId);

  const SCROLLREGISTRY = CHAIN_CONFIG[chainId].SCROLLREGISTRY;
  const BASEREGISTRARIMPLEMENTATION =
    CHAIN_CONFIG[chainId].BASEREGISTRARIMPLEMENTATION;

  const ScrollRegistryContract = new Contract(
    SCROLLREGISTRY.address,
    SCROLLREGISTRY.abi,
    provider
  );

  const BaseImplContract = new Contract(
    BASEREGISTRARIMPLEMENTATION.address,
    BASEREGISTRARIMPLEMENTATION.abi,
    provider
  );

  const tokenId = BigNumber.from(
    ethersUtils.keccak256(ethersUtils.toUtf8Bytes(name))
  );

  // 页面注册人
  const registrant = BaseImplContract.ownerOf(tokenId);
  // 页面管理人
  // const controller = nameWrapperContract.ownerOf(tokenId);
  const controller = ScrollRegistryContract.owner(
    ethersUtils.namehash(`${name}.scroll`)
  );
  // 过期时间
  const expiryData = BaseImplContract.nameExpires(tokenId);
  try {
    const resultPromise = await Promise.all([
      registrant,
      controller,
      expiryData,
    ]);
    console.log("resultresult arr", resultPromise);
    const result = {
      registrant: resultPromise[0],
      controller: resultPromise[1],
      expiryData: resultPromise[2].toString(),
    };
    console.log("resultresult", result);
    return result;
  } catch (err) {
    return {
      registrant: "",
      controller: "",
      expiryData: "0",
      nftOwner: "",
    };
  }
};

//域名费用
export const getRentPrice = async (name: string, chainId: any) => {
  console.log("getRentPricegetRentPrice", name, chainId);
  if (!name && !chainId) return;
  const provider = await getRpcProvider(chainId);

  const REGISTRARCONTROLLER = CHAIN_CONFIG[chainId].REGISTRARCONTROLLER;

  const contract = new Contract(
    REGISTRARCONTROLLER.address,
    REGISTRARCONTROLLER.abi,
    provider
  );
  const resultArr = await contract.rentPrice(name, yearsToSeconds(1));
  if (resultArr.length > 0) {
    const price = resultArr[0].toString();
    const ethPrice = new BigNumberJs(price).shiftedBy(-18).toFixed();
    return ethPrice;
  }

  return "0";
};

// 根据地址查别名
export const getNameForAddr = async (address: string, chainId: any) => {
  const provider = await getRpcProvider(chainId);

  const REVERSEREGISTRAR = CHAIN_CONFIG[chainId].REVERSEREGISTRAR;
  const PUBLICRESOLVER = CHAIN_CONFIG[chainId].PUBLICRESOLVER;
  const SCROLLREGISTRY = CHAIN_CONFIG[chainId].SCROLLREGISTRY;

  const reverseContract = new Contract(
    REVERSEREGISTRAR.address,
    REVERSEREGISTRAR.abi,
    provider
  );
  const publicContract = new Contract(
    PUBLICRESOLVER.address,
    PUBLICRESOLVER.abi,
    provider
  );
  const scrollRegistryContract = new Contract(
    SCROLLREGISTRY.address,
    SCROLLREGISTRY.abi,
    provider
  );
  const reverseRegistrarNode = await reverseContract.node(address);
  const name = await publicContract.name(reverseRegistrarNode);
  if (!name) return "";
  const isOwner = scrollRegistryContract.owner(ethersUtils.namehash(name));
  console.log("namenamenamename", name);
  return isOwner ? name : "";
};

//域名注册
export const registerName = async (
  provider: JsonRpcProvider,
  account: string,
  chainId: any,
  contractAddress: string,
  callData: any,
  value: any,
  t?: any
) => {
  try {
    const signer = await provider?.getSigner();
    if (!CHAIN_CONFIG[chainId]) {
      throw t("DRVeKjjxayJcFRIWNk");
    }
    let txnReq: any = {
      to: contractAddress,
      from: account,
      data: callData,
      value: value,
    };
    const getGasLimit: any = (await signer.estimateGas(txnReq))
      .mul(105)
      .div(100);
    const newGasLimit = getGasLimit.toString();
    if (!newGasLimit) {
      throw new Error("GasLimit error");
    }
    const tx = { ...txnReq, gasLimit: newGasLimit };
    const result = await signer.sendTransaction(tx);
    return result;
  } catch (err: any) {
    console.error("sendTransaction error", err, {
      to: contractAddress,
      from: account,
      data: callData,
      value: value,
    });
    throw errorData(err, t);
  }
};

// 域名续期
export const nameRenew = async (
  provider: JsonRpcProvider,
  account: string,
  chainId: any,
  contractAddress: string,
  callData: any,
  value: any,
  t?: any
) => {
  try {
    const signer = provider?.getSigner();
    if (!CHAIN_CONFIG[chainId]) {
      throw t("DRVeKjjxayJcFRIWNk");
    }
    let txnReq: any = {
      to: contractAddress,
      from: account,
      data: callData,
      value: value ? new BigNumberJs(value).shiftedBy(18).toFixed() : "0",
    };
    const getGasLimit: any = (await signer.estimateGas(txnReq))
      .mul(105)
      .div(100);
    const newGasLimit = getGasLimit.toString();
    if (!newGasLimit) {
      throw new Error("GasLimit error");
    }
    const tx = { ...txnReq, gasLimit: newGasLimit };
    console.log("sendTransaction tx", tx);
    const res = await signer.sendTransaction(tx);
    console.log("sendTransaction resresres", res);
    return res;
  } catch (err: any) {
    console.log("nameRenew error", err);
    throw errorData(err, t);
  }
};

// 域名所有人转移
export const transferFrom = async (
  provider: JsonRpcProvider | undefined,
  account: string,
  chainId: any,
  toAccount: string,
  name: string,
  t?: any
) => {
  const signer = provider?.getSigner();
  if (!signer || !account || !chainId) return;

  if (!CHAIN_CONFIG[chainId]) {
    throw t("DRVeKjjxayJcFRIWNk");
  }

  const BASEREGISTRARIMPLEMENTATION =
    CHAIN_CONFIG[chainId].BASEREGISTRARIMPLEMENTATION;

  const BaseImplContract = new Contract(
    BASEREGISTRARIMPLEMENTATION.address,
    BASEREGISTRARIMPLEMENTATION.abi,
    signer
  );

  try {
    const tokenId = BigNumber.from(
      ethersUtils.keccak256(ethersUtils.toUtf8Bytes(name))
    );
    const result = await BaseImplContract.transferFrom(
      account,
      toAccount,
      tokenId
    );
    console.log("resultresult transferFrom", result);
    return result;
  } catch (err: any) {
    console.error("transferFrom error", err);
    throw errorData(err, t);
  }
};

//设置管理员 reclaim
export const reclaim = async (
  provider: JsonRpcProvider | undefined,
  account: string,
  chainId: any,
  toAccount: string,
  name: string,
  t?: any
) => {
  const signer = provider?.getSigner();
  if (!signer || !account || !chainId) return;

  if (!CHAIN_CONFIG[chainId]) {
    throw t("DRVeKjjxayJcFRIWNk");
  }

  const BASEREGISTRARIMPLEMENTATION =
    CHAIN_CONFIG[chainId].BASEREGISTRARIMPLEMENTATION;

  const BaseImplContract = new Contract(
    BASEREGISTRARIMPLEMENTATION.address,
    BASEREGISTRARIMPLEMENTATION.abi,
    signer
  );

  try {
    const tokenId = BigNumber.from(
      ethersUtils.keccak256(ethersUtils.toUtf8Bytes(name))
    );
    console.log("sendTransaction reclaim parmas", name, tokenId, toAccount);
    const result = await BaseImplContract.reclaim(tokenId, toAccount);
    return result;
  } catch (err: any) {
    console.error("sendTransaction reclaim", err);
    throw errorData(err, t);
  }
};

//设置地址别名setName

export const setName = async (
  provider: JsonRpcProvider | undefined,
  account: string,
  chainId: any,
  name: string,
  t?: any
) => {
  const signer = provider?.getSigner();
  if (!signer || !account || !chainId || !name) return;

  if (!CHAIN_CONFIG[chainId]) {
    throw t("DRVeKjjxayJcFRIWNk");
  }

  const REVERSEREGISTRAR = CHAIN_CONFIG[chainId].REVERSEREGISTRAR;

  const reverseContract = new Contract(
    REVERSEREGISTRAR.address,
    REVERSEREGISTRAR.abi,
    signer
  );
  console.log("sendTransaction setName", name);
  try {
    const result = await reverseContract.setName(name);
    return result;
  } catch (err: any) {
    console.error("sendTransaction setName", err);
    throw errorData(err, t);
  }
};
