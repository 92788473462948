import { Connector } from "@web3-react/types";
import { getConnection } from "@/connection/utils";
import { useEffect, useMemo } from "react";
import { useAppSelector } from "@/state/hooks";
import { initChainId } from "@/constants";

async function connect(connector: Connector) {
  try {
    if (connector.connectEagerly) {
      await connector.connectEagerly();
    } else {
      await connector.activate(initChainId);
    }
  } catch (error) {
    console.error(`web3-react eager connection error: ${error}`);
  }
}

export default function useEagerlyConnect() {
  const selectedWallet = useAppSelector((state) => state.user.selectedWallet);

  const selectedConnection = useMemo(() => {
    if (selectedWallet) {
      return getConnection(selectedWallet);
    } else {
      return null;
    }
  }, [selectedWallet]);

  useEffect(() => {
    if (selectedConnection) {
      connect(selectedConnection.connector);
    }
  }, [selectedConnection]); // eslint-disable-line react-hooks/exhaustive-deps
}
