import ScrollL2TEST from "./ScrollL2TEST";
import ScrollSepoliaTest from "./ScrollSepoliaTest";
import ScrollMainnet from "./ScrollMainnet";

export const CHAIN_CONFIG: any = {
  ...ScrollMainnet,
  ...ScrollSepoliaTest,
  ...ScrollL2TEST,
};

export const chian_config_list = () => Object.keys(CHAIN_CONFIG);
