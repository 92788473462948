import { ReactElement } from "react";
import classNames from 'classnames';

interface CheckboxInterface {
    checked: boolean;
    onChange: Function;
    labelText?: string;
    disabled?: boolean | undefined;
}

const Checkbox = (props: CheckboxInterface): ReactElement => {
    const {
        onChange,
        checked,
        labelText,
        disabled,
    } = props;

    const onSwitchChange = (e: any): void => {
        if (disabled) return;
        console.log('----点击了切换-----', e);
        onChange && onChange(e.target.checked);
    };

    return (
        <div
            className={classNames(
                'checkbox-wrap',
                { 'checkbox-disabled': disabled }
            )}
        >
            <label className="checkbox-wrapper" onChange={onSwitchChange}>
                <span
                    className={classNames('checkbox', {
                        'checkbox-checked': checked,
                    })}
                >
                    <input
                        type="checkbox"
                        disabled={disabled}
                        defaultChecked={checked}
                        className="checkbox-input"
                    />
                    <span
                        className={classNames('checkbox-inner', {
                            'checkbox-inner-disabled': disabled,
                        })}
                    />
                </span>
                <span className="checkbox-label-text">{labelText}</span>
            </label>
        </div>
    );
};

export default Checkbox;
