import { providers } from "ethers";
import { FallbackProvider } from "@ethersproject/providers";
import { CHAIN_CONFIG } from "@/constants/chainInfo";
export const getRandomNumber = (min: number, max: number): number => {
  return Math.floor(Math.random() * (max - min + 1) + min);
};

// cached providers
// const chainProviders: Record<number, providers.FallbackProvider[]> = {};

const rpcNodes: any = {
  5: ["https://goerli.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161"],
  534353: [
    "https://alpha-rpc.scroll.io/l2",
    "https://scroll-alphanet.public.blastapi.io",
    "https://scroll-testnet.blockpi.network/v1/rpc/public",
    "https://scroll-alpha-public.unifra.io",
  ],
  534351: ["https://scroll-sepolia.blockpi.network/v1/rpc/public"],
};
// RPC Urls
export const getRpcUrl = async (chainId: number): Promise<string> => {
  const rpcUrls = await getRpcUrls(chainId);
  return rpcUrls[0];
};

export const getRpcUrls = async (chainId: number): Promise<string[]> => {
  const useChainRpcs = rpcNodes[chainId];
  return useChainRpcs ? useChainRpcs : "";
};

// const getRandomProvider = (
//   providerList: providers.FallbackProvider[]
// ): providers.FallbackProvider => {
//   const index = getRandomNumber(0, providerList.length - 1);
//   return providerList[index];
// };

// Provider
export const getRpcProvider = async (
  chainId: number
): Promise<FallbackProvider> => {
  const url = CHAIN_CONFIG[chainId]["rpcURl"][0];
  return new providers.FallbackProvider([
    new providers.StaticJsonRpcProvider(url, chainId),
  ]);
};
