import { useMemo, useState, useCallback, useRef } from "react";
import type { Connector } from "@web3-react/types";
import { useWeb3React } from "@web3-react/core";
import { CHAIN_CONFIG } from "@/constants/chainInfo";
import { DialogOverlay, DialogContent } from "@reach/dialog";
import { ReactComponent as CheckChain } from "@/assets/svg/check-chain.svg";
import unknown from "@/assets/images/unknown.png";
import { initChainId, addChainInfos } from "@/constants";
import { useAppSelector, useAppDispatch } from "@/state/hooks";
import { updateShowLoginDialog } from "@/state/user/reducer";
import { useLocation, useNavigate } from "react-router-dom";
import { getLastPathName } from "../../../utils";
import { UserLanguageArrowDownIcon } from "@/assets/svg-iconfont";
import MirHidden from "../MirHidden";
import { isMobile } from "react-device-detect";
const ChainInfo = () => {
  const [showChainDialog, setShowChainDialog] = useState(false);
  const chainEl = useRef<any>(null);
  const { account, chainId, connector } = useWeb3React();
  const dispatch = useAppDispatch();
  const selectedWallet = useAppSelector((state) => state.user.selectedWallet);
  const location = useLocation();
  const navigate = useNavigate();

  const { pathname } = location;

  const connectChain = useMemo(() => {
    if (chainId) {
      if (CHAIN_CONFIG[chainId]) {
        return CHAIN_CONFIG[chainId];
      } else {
        return {
          chain: "unknown",
          logo: unknown,
        };
      }
    }
    return CHAIN_CONFIG[initChainId];
  }, [chainId]);

  const isViewAndRegister =
    getLastPathName(pathname) === "view" ||
    getLastPathName(pathname) === "register";

  const open = () => {
    console.log("chainElchainEl", chainEl.current.offsetLeft);
    setShowChainDialog(true);
  };
  const close = () => setShowChainDialog(false);

  const tryActivation = useCallback(
    async (connector: Connector, wallet: string, useChainId: number) => {
      console.log("tryActivation", useChainId);
      try {
        await connector.activate(useChainId || initChainId);
        if (isViewAndRegister) {
          navigate("/");
        }
      } catch (error: any) {
        console.log(`web3-react connection error:`, wallet, error);
        if (
          wallet === "METAMSDK" &&
          error?.code === 4902 &&
          connector?.provider
        ) {
          const provider: any = connector.provider;
          const addChainInfo: any = addChainInfos[useChainId] || null;
          if (!addChainInfo) return;
          provider.send("wallet_addEthereumChain", [addChainInfo]).then(() => {
            tryActivation(connector, wallet, useChainId);
          });
          return;
        }
      }
    },
    [dispatch]
  );

  const pageSelectChain = async (useChainId: number) => {
    if (!account) {
      dispatch(updateShowLoginDialog({ showLoginDialog: true }));
      return;
    }
    if (useChainId === chainId) {
      close();
      return;
    }
    close();
    tryActivation(connector, selectedWallet, useChainId);
  };
  const chainLists = [
    CHAIN_CONFIG[534352],
    CHAIN_CONFIG[534351],
    // CHAIN_CONFIG[534353],
  ];
  //Object.values(CHAIN_CONFIG);
  if (!connectChain?.chain) return null;
  return (
    <>
      <div className="scroll-chain-logo" onClick={open} ref={chainEl}>
        {connectChain?.logo ? (
          <img src={connectChain.logo} alt={connectChain?.chain} />
        ) : null}
        <MirHidden smUp>
          <div className="user-language-m-left">
            <span>{connectChain?.chain}</span>
            <UserLanguageArrowDownIcon />
          </div>
        </MirHidden>
        <MirHidden smDown>
          <span>{connectChain?.chain}</span>
          <UserLanguageArrowDownIcon />
        </MirHidden>
      </div>
      <DialogOverlay
        isOpen={showChainDialog}
        onDismiss={close}
        dangerouslyBypassFocusLock
      >
        <DialogContent
          className="chain-modal"
          style={!isMobile ? { left: chainEl?.current?.offsetLeft } : {}}
        >
          <div className="content">
            {chainLists.map((item: any) => (
              <div
                className="chain-info-item"
                key={item.chainId}
                onClick={() => {
                  pageSelectChain(item.chainId);
                }}
              >
                <div className="chain-info-item_name">
                  <img src={item.logo} alt={item?.chain} />
                  {item.chain}
                </div>
                {connectChain?.chainId === item.chainId ? <CheckChain /> : null}
              </div>
            ))}
          </div>
        </DialogContent>
      </DialogOverlay>
    </>
  );
};

export default ChainInfo;
