import { Connector } from "@web3-react/types";
import {
  ConnectionType,
  metamaskConnection,
  openblockConnection,
  innerOpenblockConnection,
} from "./index";

export function getIsInjected(): boolean {
  return Boolean(window.ethereum);
}

export function getIsMetaMask(): boolean {
  return window.ethereum?.isMetaMask ?? false;
}

export function getIsCoinbaseWallet(): boolean {
  return window.ethereum?.isCoinbaseWallet ?? false;
}

const CONNECTIONS = [
  metamaskConnection,
  openblockConnection,
  innerOpenblockConnection,
];
export function getConnection(c: Connector | ConnectionType) {
  if (c instanceof Connector) {
    const connection = CONNECTIONS.find(
      (connection) => connection.connector === c
    );
    if (!connection) {
      throw Error("unsupported connector");
    }
    return connection;
  } else {
    switch (c) {
      case ConnectionType.OPENBLOCK:
        return openblockConnection;
      case ConnectionType.METAMSDK:
        return metamaskConnection;
      case ConnectionType.INNEROPENBLOCK:
        return innerOpenblockConnection;
    }
  }
}

export function getConnectionName(connectionType: ConnectionType) {
  switch (connectionType) {
    case ConnectionType.OPENBLOCK:
      return "OpenBlock";
    case ConnectionType.METAMSDK:
      return "MetaMask";
    case ConnectionType.INNEROPENBLOCK:
      return "InnerOpenBlock";
  }
}
