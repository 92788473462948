import { request } from "../../utils/request";
import {
  MY_DOMAIN_LIST,
  INVITE_RECORD_TOP,
  PROOF,
  INVITE_RECORD,
  PRICE_ETH,
} from "@/constants/requestAPI";

export const getMyDomainList = (params: any): Promise<any> => {
  return request(MY_DOMAIN_LIST, {
    params,
  });
};

export const getInviteRecordTop = (params: any): Promise<any> => {
  return request(INVITE_RECORD_TOP, { params });
};

export const getProof = (params: { account: string }) => {
  return request(PROOF, {
    params,
  });
};

export const getInviteRecord = (params: {
  wallet: string;
  sig: string;
}): Promise<any> => {
  return request(INVITE_RECORD, {
    params,
  });
};

export const createInviteRecord = (body: any): Promise<any> => {
  return request(INVITE_RECORD, {
    body,
  });
};

export const getEthPrice = (): Promise<any> => {
  return request(PRICE_ETH);
};
