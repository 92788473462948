import { useEffect, useMemo, useState } from "react";
import { getNameForAddr } from "@/helper/contract";
const useNameForAddr = (address?: string, chainId?: any) => {
  const [name, setName] = useState("");
  useEffect(() => {
    if (address && chainId) {
      getNameForAddr(address, chainId)
        .then((res: string) => {
          setName(res);
        })
        .catch(() => {
          setName("");
        });
    } else {
      setName("");
    }
  }, [address, chainId]);
  return useMemo(() => name, [name]);
};

export default useNameForAddr;
