export const ZERO_ADDRESS = "0x0000000000000000000000000000000000000000";

export const initChainId = 534352;

export const batch = 1;

export const batchDomainLength: any = {
  1: 5,
  2: 4,
};

export const statusConstants: any = {
  loading: "loading",
  shortErr: "EePllyAaAoZPlkvaap",
  available: "OblKUfdvEBvBZtbXC",
  registered: "MnDuyNiIGkWSIxowRrTV",
  notsale: "ELgPmNQPkrYbkTFzZUo",
  release: "OblKUfdvEBvBZtbXC",
  invalid: "HdZfQiYeCHPLeFYiV",
};

export const scrollL2TestInfo = {
  chainId: `0x82751`,
  chainName: "Scroll Alpha Testnet",
  nativeCurrency: {
    symbol: "ETH",
    decimals: 18,
  },
  rpcUrls: ["https://alpha-rpc.scroll.io/l2"],
  blockExplorerUrls: ["https://blockscout.scroll.io"],
};

export const ScrollSepoliaTestInfo = {
  chainId: `0x8274f`,
  chainName: "Scroll Sepolia Testnet",
  nativeCurrency: {
    symbol: "ETH",
    decimals: 18,
  },
  rpcUrls: ["https://scroll-sepolia.blockpi.network/v1/rpc/public"],
  blockExplorerUrls: ["https://sepolia-blockscout.scroll.io"],
};

export const ScrollMainnetInfo = {
  chainId: `0x82750`,
  chainName: "Scroll Mainnet",
  nativeCurrency: {
    symbol: "ETH",
    decimals: 18,
  },
  rpcUrls: ["https://scroll.blockpi.network/v1/rpc/public"],
  blockExplorerUrls: ["https://blockscout.scroll.io"],
};

export const initChainInfo = ScrollMainnetInfo;

export const addChainInfos: any = {
  534353: scrollL2TestInfo,
  534351: ScrollSepoliaTestInfo,
  534352: ScrollMainnetInfo,
};
