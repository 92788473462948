import {
  useState,
  useCallback,
  useMemo,
  useEffect,
  ReactElement,
  MouseEvent,
} from "react";
import { useWeb3React } from "@web3-react/core";
import type { Connector } from "@web3-react/types";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppSelector, useAppDispatch } from "@/state/hooks";
import { DialogOverlay, DialogContent } from "@reach/dialog";
import SearchInput from "@/components/ui/SearchInput";
import { showShortenAddress } from "@/utils";
import { getConnection } from "@/connection/utils";
import { metamaskConnection, openblockConnection } from "@/connection";
import {
  updateSelectedWallet,
  updateLanguage,
  updateShowLoginDialog,
} from "@/state/user/reducer";
import copyToClipboard from "copy-to-clipboard";
import { infoToastTip } from "@/components/ui/ToastTip";
import { ReactComponent as CloseIcon } from "@/assets/svg/close.svg";
import { ReactComponent as MetamaskLogo } from "@/assets/svg/metamask-logo.svg";
import { ReactComponent as OpenblockLogo } from "@/assets/svg/openblock-logo.svg";
import { ReactComponent as ArrowDown } from "@/assets/svg/arrow-down.svg";
import { ReactComponent as LogOutIcon } from "@/assets/svg/logout.svg";
import { ReactComponent as ArrowRight } from "@/assets/svg/arrow-right.svg";
import { ReactComponent as LogoIcon } from "@/assets/svg/logo.svg";
import { ReactComponent as CopyIcon } from "@/assets/svg/copy.svg";
import { ReactComponent as LogoSmallIcon } from "@/assets/svg/logo-small.svg";
import { ReactComponent as HeaderWalletIcon } from "@/assets/svg/header-wallet.svg";
import { ReactComponent as HeaderMenuIcon } from "@/assets/svg/header-menu.svg";
import { ReactComponent as HeaderMyDomainArrowIcon } from "@/assets/svg/primary-color-arrow-right.svg";


import {
  UserLanguageIcon,
  UserLanguageArrowDownIcon,
} from "@/assets/svg-iconfont";
import { webClickEvent } from "@/helper/utils/sensors";
import useTranslation from "@/hooks/useTranslation";
import ChainInfo from "./chainInfo";
import { initChainId, initChainInfo } from "@/constants";
import { CHAIN_CONFIG } from "@/constants/chainInfo";
import MirHidden from "../MirHidden";
import { isMobile } from "react-device-detect";
interface WalletsInterface {
  name: string;
  logo: ReactElement;
  connector: Connector;
  wallet: string;
  des?: string;
}

interface LanguageArrInterface {
  label: string;
  value: string;
}
interface UserButtonProps {
  isMobile?: boolean;
}
declare const window: Window & {isOBDappBrowser:boolean}
const UserButton = (props:UserButtonProps) => {
  const { isMobile } = props;
  const { connector, account } = useWeb3React();
  const [showLogOutDialog, setShowLogOutDialog] = useState(false);
  const [selectWallet, setSelectWallet] = useState<WalletsInterface>();
  const [loadingWallet, setLoadingWallet] = useState("");
  const [showTutorialAlert, setShowTutorialAlert] = useState(false);
  const [tutorials, setTutorials] = useState([]);
  const language = useAppSelector((state) => state.user.language);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const domainName = useAppSelector((state) => state.domainManager.domainName);
  const showLoginDialog = useAppSelector((state) => state.user.showLoginDialog);
  const step3Title = t('CVaasqzogQmyMJriVJJKT');
  const step1Title = t('CVaasqzogQmyMJriVJJKM')
      .replace('<a>', "<a href='https://openblock.com' style='color:#BAD25A' target='_blank' >")
      .replaceAll('<span>', "<span style='color:#BAD25A'>");
  const bridgeurl = 'https://openblock.com/#/download';
  const step2Title = t('CVaasqzogQmyMJriVJJKN')
      .replace('<a>', `<a href=${bridgeurl} style='color:#BAD25A' target='_blank' >`);
  const titles = [step1Title,step2Title,step3Title];
  const isIframe = window.self !== window.top;

  const open = () => {
    if (isIframe) return;
    dispatch(updateShowLoginDialog({ showLoginDialog: true }));
  };

  const close = () => {
    dispatch(updateShowLoginDialog({ showLoginDialog: false }));
    setLoadingWallet("");
  };

  const goRegisterFunc = (e: MouseEvent<HTMLElement>): void => {
    e.stopPropagation();
    webClickEvent("Scroll_Ag_oblink");
    isMobile? window.open("https://openblock.com/#/initialize/welcome", "_blank"):setShowTutorialAlert(true);
  };
  const renderOBDesc = ()=>{
    return (
    <div
    className="obdesc"
    dangerouslySetInnerHTML={{__html:t('juBSiTxasfWnUuIe')
      .replace('<a>', "<a href='https://openblock.com' style='color:#4A3DE6' target='_blank' >")}}>
    </div>
  )
  }
  let wallets: Array<WalletsInterface> = [
    // {
    //   name: "OpenBlock",
    //   logo: <OpenblockLogo />,
    //   des: t("juBSiTxasfWnUuIe"),
    //   connector: openblockConnection.connector,
    //   wallet: "OPENBLOCK",
    // },
    // {
    //   name: "MetaMask",
    //   logo: <MetamaskLogo />,
    //   connector: metamaskConnection.connector,
    //   wallet: "METAMSDK",
    // }
    {
      name: "OpenBlock/MetaMask",
      logo:  <><img src="/images/wt/icon.png" alt="" width={81} height={66}/></>,
      connector: metamaskConnection.connector,
      des: t("juBSiTxasfWnUuIe"),
      wallet: "METAMSDK",
    }
  ];
  if(isMobile){
    wallets = [
      {
        name: "OpenBlock",
        logo: <OpenblockLogo />,
        des: t("juBSiTxasfWnUuIe"),
        connector: openblockConnection.connector,
        wallet: "OPENBLOCK",
      },
    ];
  }
  if (window?.isOBDappBrowser){
    wallets = [
      {
        name: "OpenBlock",
        logo: <OpenblockLogo />,
        connector: metamaskConnection.connector,
        wallet: "METAMSDK",
      }
    ];
  }
  useEffect(() => {
  setTutorials([]);
  const params:any = [];
  for (let index = 1; index < 4; index++) {
   const imgurl = `/images/wt/step${index}.${language}.png`
   params.push({imgurl})
  }
   setTutorials(params);
  }, [language]);

 useEffect(() => {
    const type = getConnection(connector)?.type || "";
    const filterWallets = type
      ? wallets.filter((item: any) => item.wallet === type)
      : [];
      setSelectWallet(filterWallets[0]);
  }, [connector]);

  const tryActivation = useCallback(
    async (connector: Connector, wallet: string) => {
      try {
        setLoadingWallet(wallet);
        await connector.activate(initChainId);
        dispatch(updateShowLoginDialog({ showLoginDialog: false }));
        dispatch(updateSelectedWallet({ wallet }));
        webClickEvent("Scroll_Ag_walletconnect");
        setLoadingWallet("");
      } catch (error: any) {
        console.log(`web3-react connection error:`, wallet, error);
        if (
          wallet === "METAMSDK" &&
          error?.code === 4902 &&
          connector?.provider
        ) {
          const provider: any = connector.provider;
          provider.send("wallet_addEthereumChain", [initChainInfo]).then(() => {
            tryActivation(connector, wallet);
          });
          return;
        }
        setLoadingWallet("");
      }
    },
    [dispatch]
  );

  const disconnectWallet = () => {
    if (connector.deactivate) {
      connector.deactivate?.();
    } else {
      connector.resetState();
    }
    setShowLogOutDialog(false);
    dispatch(updateSelectedWallet({ wallet: "" }));
  };

  const clickCopyFun = (copyValue: string) => {
    copyToClipboard(copyValue);
    infoToastTip({
      status: "success",
      title: t("nBMZbjmYxYoLgLGLz"),
      toastId: `setUpdate${new Date().valueOf()}`,
    });
  };

  const openUserAccount = () => {
    if (isIframe) return;
    if(!selectWallet?.name){
      disconnectWallet();
      return;
    }
    setShowLogOutDialog(true);
  };
  const isInjected = Boolean(window.ethereum);
  return (
    <>
      {account ? (
        <div className="user-button" onClick={openUserAccount}>
          {domainName ? domainName : showShortenAddress(account)}
          {!isIframe ? <ArrowDown /> : null}
        </div>
      ) : (
        <div>
          {!isMobile?( <div className="user-button" onClick={open}>
            {t("YkqSDwKovDFmDjUTnt")}
            </div>) : (<div  onClick={open}>
              <HeaderWalletIcon />
            </div>)}
        </div>
      )}
      <DialogOverlay
        isOpen={showLoginDialog}
        onDismiss={close}
        dangerouslyBypassFocusLock
      >
        <DialogContent className="wallet-modal">
          <div>
            <div className="header">
              <div className="header-title">{t("YkqSDwKovDFmDjUTnt")}</div>
              <div className="close-icon" onClick={close}>
                <CloseIcon />
              </div>
            </div>
            <div className="content">
              {wallets.map((item: any) => (
                <>
                <div
                  className="wallet-item"
                  key={item.name}
                  onClick={() => {
                    tryActivation(item.connector, item.wallet);
                  }}
                >
                  <div className="wallet-item_title">
                    <div className="wallet-item_title_left">
                      <div className="wallet-item_logo">{item.logo}</div>
                      <span className="wallet-item_name">{item.name}</span>
                    </div>
                    {item.name === "OpenBlock/MetaMask" && !isInjected ? (
                      <span className="wallet-item_uninjected">
                        {t("ZkOPSEmFIvwVIwZHnQu")}
                      </span>
                    ) : loadingWallet === item.wallet ? (
                      <div className="wallet-item_title_loading" />
                    ) : (
                      <ArrowRight />
                    )}
                  </div>
                  
                </div>
                {item?.des && (
                    <div className="wallet-item_des">
                      <div className="wallet-item_des_left">
                        <span>{t("NRfegLMSqsHHxeBVcGaC")}</span>
                        {renderOBDesc()}
                      </div>
                      <div
                        className="wallet-item_des_right"
                        onClick={(e) => goRegisterFunc(e)}
                      >
                        {isMobile? t("giqXEQsxhoBSyMqSpRWd"): t("CVaasqzogQmyMJriVJJK")}
                      </div>
                    </div>
                  )}
                </>
                
              ))}
            </div>
          </div>
        </DialogContent>
      </DialogOverlay>
      <DialogOverlay
        style={{ background: "unset" }}
        isOpen={showLogOutDialog}
        onDismiss={() => setShowLogOutDialog(false)}
      >
        <DialogContent className="wallet-logout-modal">
          <div className="wallet-logout-modal_wallet">
            <div className="wallet-logout-modal_wallet_info">
              {selectWallet?.logo} <span>{selectWallet?.name}</span>
            </div>
            <div
              className="wallet-logout-modal_wallet_logout"
              onClick={disconnectWallet}
            >
              <LogOutIcon />
              Disconnect
            </div>
          </div>
          <div className="wallet-logout-modal_wallet-address">
            <div className="wallet-logout-modal_wallet-address-logo">
              <LogoSmallIcon />
            </div>
            <div className="wallet-logout-modal_wallet-address-val">
              {account ? showShortenAddress(account) : ""}
            </div>
            {account ? (
              <div
                className="wallet-logout-modal_wallet-address-copy"
                onClick={() => {
                  clickCopyFun(account);
                }}
              >
                <CopyIcon />
              </div>
            ) : null}
          </div>
        </DialogContent>
      </DialogOverlay>
      <DialogOverlay
        style={{ background: "unset" }}
        isOpen={showTutorialAlert}
        onDismiss={() => setShowTutorialAlert(false)}
      >
        <DialogContent className="wallet-tutorial">
          <div className="wt-header">
            <div className="wt-header-close-icon" onClick={() => setShowTutorialAlert(false)}>
              <CloseIcon />
            </div>
            <div className="wt-header-title">{t("CVaasqzogQmyMJriVJJK")}</div>
          </div>
          {
            tutorials.map((item: any,index) => (
              <>
                <div className="wt-content-item" key={item.title}>
                  <div className="wt-content-item-title" dangerouslySetInnerHTML={{__html:titles[index]}}></div>
                  <img className="wt-content-item-img" src={item.imgurl} alt=""/>
                </div>
              </>))
          }
        </DialogContent>
      </DialogOverlay>
    </>
  );
};

const UserLanguageButton = () => {
  const dispatch = useAppDispatch();
  const [showLanguage, setShowLanguage] = useState(false);
  const language = useAppSelector((state) => state.user.language);

  const languageArr: Array<LanguageArrInterface> = [
    {
      label: "English",
      value: "en",
    },
    {
      label: "简体",
      value: "zh_CN",
    },
    {
      label: "繁体",
      value: "zh_TW",
    },
  ];

  const setLanguageFunc = (val: string): void => {
    dispatch(updateLanguage({ language: val }));
  };

  const languageText = languageArr.filter((cur) => cur.value === language);

  return (
    <>
      <div className="user-language" onClick={() => setShowLanguage(true)}>
        <UserLanguageIcon />
        <MirHidden smUp>
          <div className="user-language-m-left">
            <span>{languageText[0].label}</span>
            <UserLanguageArrowDownIcon />
          </div>
        </MirHidden>
        <MirHidden smDown>
          <span>{languageText[0].label}</span>
          <UserLanguageArrowDownIcon />
        </MirHidden>
      </div>
      <DialogOverlay
        style={{ background: "unset" }}
        isOpen={showLanguage}
        onDismiss={() => setShowLanguage(false)}
      >
        <DialogContent className="user-language-modal">
          {languageArr.map((cur) => {
            return (
              <div key={cur.label} onClick={() => {
                setLanguageFunc(cur.value);
                setShowLanguage(false);
              }}>
                {cur.label}
              </div>
            );
          })}
        </DialogContent>
      </DialogOverlay>
    </>
  );
};

const Header: any = () => {
  let location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { account, chainId } = useWeb3React();
  const goMyDomainFunc = (): void => {
    navigate(`/my`);
  };
  const [showMenuList, setShowMenuList] = useState(false);
  // const goWhiteListFunc = (): void => {
  //   window.open("", "_blank");
  // };
  const goHomeFunc = (): void => {
    navigate(`/`);
  };

  return (
    <>
      <MirHidden smDown>
        <div className="page-header">
          <div className="page-header-logo">
            <div className="page-header-logo-icon" onClick={goHomeFunc}>
              <LogoIcon />
            </div>
            {location?.pathname !== "/" && (
              <div className="page-header-search">
                <SearchInput size={"medium"} />
              </div>
            )}
          </div>
          <div className="page-header-right">
            {account && chainId && CHAIN_CONFIG[chainId]?.chainId ? (
              <div className="my-domain-btn" onClick={goMyDomainFunc}>
                {t("QevesDuuBJEePdMdL")}
              </div>
            ) : null}
            {/* <div className="white-list-btn" onClick={goWhiteListFunc}>
              白名单
            </div> */}
            <ChainInfo />
            <UserButton />
            <UserLanguageButton />
          </div>
        </div>
      </MirHidden>
      <MirHidden smUp>
        <div className="page-header">
          <div className="page-header-m-wallet" onClick={()=>{
            setShowMenuList(false);
          }}>
            {/* <HeaderWalletIcon /> */}
            <UserButton isMobile={true}/>
          </div>
          <div className="page-header-m-logo" onClick={()=>{
            goHomeFunc();
            setShowMenuList(false);
          }}>
            <LogoIcon />
          </div>
          <div className="page-header-m-menu" onClick={()=>{
            setShowMenuList(!showMenuList);
          }}>
            <HeaderMenuIcon />
          </div>
        </div>
        <MirHidden smUp>
          {showMenuList ? (<div className="page-header-m-menu-list">
            <div className="page-header-m-menu-list-item"><ChainInfo /></div>
            <div className="page-header-m-menu-list-item"><UserLanguageButton /></div>
            {account && chainId && CHAIN_CONFIG[chainId]?.chainId ? (
                <div className="page-header-m-menu-list-my-domain" onClick={()=>{
                  setShowMenuList(!showMenuList);
                  goMyDomainFunc();
                }}>
                  <div className="my-domain-btn" >
                    {t("QevesDuuBJEePdMdL")}
                  </div>
                  <HeaderMyDomainArrowIcon />
              </div>
            ) : null}
          </div>):null}
        </MirHidden>
      </MirHidden>
    </>
    
  );
};

export default Header;
