import { useQuery } from "@tanstack/react-query";
import { getInviteRecord } from "@/pages/api";
import { useAppSelector } from "@/state/hooks";

const getStepTotal = (value: number) => {
  if (value < 5) {
    return 5;
  } else if (value < 15) {
    return 15;
  } else if (value < 30) {
    return 30;
  } else {
    return 30;
  }
};

const getCurrentTotal = (value: number) => {
  if (value === 30 || value > 30) {
    return 30;
  } else {
    return value;
  }
};

const getTotalBox = (value: number) => {
  if (value === 5 || (value > 5 && value < 15)) {
    return 1;
  } else if (value === 15 || (value > 15 && value < 30)) {
    return 2;
  } else if (value === 30 || value > 30) {
    return 3;
  } else {
    return 0;
  }
};

const useInviteRecord = (address: string) => {
  const { personalSign } = useAppSelector((state: any) => state.user);
  const isEnabled =
    address && personalSign && personalSign[address] ? true : false;
  const { data: userData, isLoading } = useQuery({
    queryKey: ["userInviteRecord", address],
    enabled: isEnabled,
    refetchInterval: 3 * 60 * 1000,
    staleTime: 3 * 60 * 1000,
    queryFn: async () => {
      const { data } = await getInviteRecord({
        wallet: address,
        sig: personalSign[address],
      });
      const result: any = {
        invite_wallets: data?.invite_wallets || 0, // 邀请的用户总数
        invitee_domains: data?.invitee_domains || 0, // 邀请的用户购买的域名总数（一个被邀请用户可购买多个域名）
        rank: data?.rank || 0, // 排名（注意该字段未返回则说明不到进入排名的门槛）
        is_registered: data?.is_registered || false,
        invite_code: data?.invite_code || "",
        records: data?.records || [],
      };
      result.stepTotal = getStepTotal(result.invite_wallets);
      result.currentTotal = getCurrentTotal(result.invite_wallets);
      result.totalBox = getTotalBox(result.invite_wallets);
      return result;
    },
  });

  return {
    userData,
    isLoading,
  };
};

export default useInviteRecord;
