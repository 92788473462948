import ScrollLogoIcon from "@/assets/svg/scroll-logo.svg";
import REGISTRARCONTROLLER from "@/abis/scrollMainnet/RegistrarController.json";
import RESERVATIONDOMAIN from "@/abis/scrollMainnet/ReservationDomain.json";
import BASEREGISTRARIMPLEMENTATION from "@/abis/scrollMainnet/BaseRegistrarImplementation.json";
import REVERSEREGISTRAR from "@/abis/scrollMainnet/ReverseRegistrar.json";
import PUBLICRESOLVER from "@/abis/scrollMainnet/PublicResolver.json";
import SCROLLREGISTRY from "@/abis/scrollMainnet/ScrollRegistry.json";

export default {
  534352: {
    chain: "Scroll Mainnet",
    chainId: 534352,
    rpcURl: ["https://scroll.blockpi.network/v1/rpc/public"],
    blockExplorerUrl: "https://blockscout.scroll.io/",
    blockExplorerTxUrl: "https://blockscout.scroll.io/tx/",
    blockExplorerAddrUrl: "https://blockscout.scroll.io/address/",
    logo: ScrollLogoIcon,
    REGISTRARCONTROLLER,
    RESERVATIONDOMAIN,
    BASEREGISTRARIMPLEMENTATION,
    REVERSEREGISTRAR,
    PUBLICRESOLVER,
    SCROLLREGISTRY,
  },
};
