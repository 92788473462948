import sensors from "sa-sdk-javascript";

const sensorsUrl = process.env.REACT_APP_SENSORS;
const sensorsWebUrl = process.env.REACT_APP_WEB_URL;
export const initSensors = () => {
  if (!sensorsUrl) {
    return;
  }
  sensors.init({
    server_url: sensorsUrl,
    web_url: sensorsWebUrl,
    is_single_page: true,
    use_client_time: true,
    send_type: "ajax",
    heatmap: {
      clickmap: "not_collect",
      scroll_notice_map: "not_collect",
    },
    show_log: false,
  });
  
  // sensors.quick("autoTrack", {
  //   platForm: "h5",
  // });

  sensors.quick("isReady", function () {
    // const sensorsId = sensors.store.getDistinctId();
    // if (userID && userID !== sensorsId) {
    //   sensors.identify(userID, true);
    //   sensors.login(userID);
    // }
  });

  window.sensors = sensors;
};
