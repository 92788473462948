import { getNameForAddr } from "./index";
import { store as reduxStore } from "@/state";
import { updateDomainName } from "@/state/domainManager/reducer";

const reSetDomainName = (address: string, chainId: number) => {
  getNameForAddr(address, chainId)
    .then((res: string) => {
      reduxStore.dispatch(updateDomainName({ name: res }));
    })
    .catch(() => {
      reduxStore.dispatch(updateDomainName({ name: "" }));
    });
};

export default reSetDomainName;
