import { KeyboardEvent, useMemo, useRef, useState } from "react";
import { useAppSelector } from "@/state/hooks";
import { useNavigate } from "react-router-dom";
import classnames from "classnames";
import SearchItem from "./SearchItem";
import { ReactComponent as SearchIcon } from "@/assets/svg/search.svg";
import { ReactComponent as CloseIcon } from "@/assets/svg/close.svg";
import { webClickEvent } from "@/helper/utils/sensors";
import useTranslation from "@/hooks/useTranslation";
import { initChainId } from "@/constants";
import { useWeb3React } from "@web3-react/core";
import { CHAIN_CONFIG } from "@/constants/chainInfo";

const SearchInput = ({
  size = "extraLarge",
}: {
  size: "medium" | "extraLarge";
}) => {
  const navigate = useNavigate();
  const inputEl = useRef<any>(null);
  const [inputVal, setInputVal] = useState("");
  const [isFocus, setIsFocus] = useState(false);
  const [isInner, setIsInner] = useState(false);
  const [isOpenResult, setOpenResult] = useState(false);
  const { historySearch, userProof } = useAppSelector((state) => state.user);
  const { t } = useTranslation();
  const { chainId } = useWeb3React();

  const useChainId = useMemo(() => {
    if (chainId && CHAIN_CONFIG[chainId]) {
      return chainId;
    }
    return initChainId;
  }, [chainId]);

  const showResult = useMemo(() => {
    if (inputVal) {
      const newNameSearch = [`${inputVal.toLowerCase()}`];
      return newNameSearch;
    }
    if (isOpenResult) {
      return [...historySearch].reverse();
    }
    return [];
  }, [inputVal, isOpenResult, historySearch]);

  let resultItemIndex = -1;

  const clearInputValFunc = (): void => {
    setInputVal("");
    if (inputEl?.current) {
      inputEl?.current?.focus();
    }
  };

  const checkKeyCode = (e: KeyboardEvent<HTMLInputElement>): void => {
    const resultItemDom = document.querySelectorAll(".result-item");
    switch (e.keyCode) {
      case 38: //上
        if (resultItemIndex === -1) {
          resultItemIndex = resultItemDom.length - 1;
        } else {
          resultItemDom[resultItemIndex].classList.remove("result-item-active");
          resultItemIndex = resultItemIndex - 1;
        }
        if (resultItemIndex < 0) {
          resultItemIndex = resultItemDom.length - 1;
        }
        resultItemDom[resultItemIndex].classList.add("result-item-active");
        break;
      case 40: //下
        if (resultItemIndex === -1) {
          resultItemIndex = 0;
        } else {
          resultItemDom[resultItemIndex].classList.remove("result-item-active");
          resultItemIndex = resultItemIndex + 1;
        }
        if (resultItemIndex >= resultItemDom.length) {
          resultItemIndex = 0;
        }
        resultItemDom[resultItemIndex].classList.add("result-item-active");
        break;
      case 13: //回车选择
        if (resultItemIndex !== -1) {
          const label =
            resultItemDom[resultItemIndex]?.querySelector(".result-item-label")
              ?.innerHTML || "";
          const status =
            resultItemDom[resultItemIndex]
              ?.querySelector(".result-item-status")
              ?.getAttribute("data-status") || "";
          if (label && status) {
            goToDetail(status, label.replace(".scroll", ""));
          }
        }
        break;
    }
  };

  const goToDetail = (status: string, inputValue: string): void => {
    if (status === "available") {
      setInputVal("");
      return navigate(`/name/${inputValue}/register`);
    }
    if (status === "registered") {
      setInputVal("");
      return navigate(`/name/${inputValue}/view`);
    }
  };

  return (
    <div
      className={classnames("search-input-box", `search-input-box_${size}`)}
      id="search-box"
      onMouseEnter={() => {
        setIsInner(true);
      }}
      onMouseLeave={() => {
        setIsInner(false);
      }}
    >
      <div
        className={classnames("search-wrapper", `search-wrapper_${size}`, {
          "result-open": showResult?.length && size !== "medium",
          "search-wrapper_medium_active": size === "medium" && isFocus,
        })}
      >
        <SearchIcon />
        <input
          value={inputVal}
          ref={inputEl}
          placeholder={t("InaJLMndbzmTpKbI")}
          onChange={(e) => {
            let value: any = e.target.value;
            if (value && value.toLowerCase().endsWith(".scroll")) {
              value = value.replace(".scroll", "");
            }
            console.log("valuevalue", value);
            if (!value) {
              setInputVal("");
              return;
            }
            if (value?.length > 40) {
              return;
            }
            setInputVal(value);
          }}
          onFocus={() => {
            setIsFocus(true);
            setOpenResult(true);
            webClickEvent("Scroll_Ag_input");
          }}
          onBlur={() => {
            setIsFocus(false);
            if (!isInner) {
              setOpenResult(false);
            }
          }}
          onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => checkKeyCode(e)}
        />
        {inputVal && (
          <div className="search-input-clear" onClick={clearInputValFunc}>
            <CloseIcon />
          </div>
        )}
      </div>
      <div
        className={classnames({
          "search-input-box_medium_padding":
            showResult?.length && size === "medium",
        })}
      >
        <div
          className={classnames("search-result-wrapper", {
            "search-result-open": showResult?.length,
          })}
        >
          {showResult.map((item: any, index: number) => (
            <SearchItem
              key={`${index}${useChainId}${item}${
                userProof?.permission || "-"
              }`}
              inputValue={item}
              setInputVal={setInputVal}
              userProof={userProof}
              setOpenResult={setOpenResult}
              chainId={useChainId}
              t={t}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default SearchInput;
