import { useMemo, useState } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { useWeb3React } from "@web3-react/core";
import classNames from "classnames";
import useRegisterParams from "@/hooks/useRegisterParams";
import useRentPrice from "@/hooks/useRentPrice";
import { registerName } from "@/helper/contract";
import { ReactComponent as CopyIcon } from "@/assets/svg/copy.svg";
import { ReactComponent as MinusIcon } from "@/assets/svg/minus.svg";
import { ReactComponent as PlusIcon } from "@/assets/svg/plus.svg";
import BigNumberJs from "bignumber.js";
import { useAppSelector, useAppDispatch } from "@/state/hooks";
import { createInviteRecord } from "@/pages/api";
import { txAwait } from "@/helper/utils/txAwait";
import reSetDomainName from "@/helper/contract/reSetDomainName";
import { addLocalDomainName } from "@/state/domainManager/init";
import {
  updateLocalRegistered,
  updateShowLoginDialog,
} from "@/state/user/reducer";
import dayjs from "dayjs";
import { getUsdPrice } from "@/utils";
import copyToClipboard from "copy-to-clipboard";
import { infoToastTip } from "@/components/ui/ToastTip";
import { webClickEvent } from "@/helper/utils/sensors";
import useTranslation from "@/hooks/useTranslation";
import { statusConstants } from "@/constants";
import useNameStatus from "@/hooks/useNameStatus";
import { initChainId } from "@/constants";
import { CHAIN_CONFIG } from "@/constants/chainInfo";
import { ReactComponent as GoBackArrowIcon } from "@/assets/svg/go-back-arrow.svg";
import { ReactComponent as CloseIcon } from "@/assets/svg/add.svg";
import { ReactComponent as DangerousIcon } from "@/assets/svg/info-circle.svg";
const NameRegister = () => {
  const { name: paramsName }: any = useParams();
  const name = paramsName ? paramsName.toLowerCase() : "";
  const navigate = useNavigate();
  const location = useLocation();
  const [buyLoading, setBuyLoading] = useState(false);
  const [year, setYear] = useState<any>(1);
  const [flag, setFlag] = useState(false);
  const [showInsufficientBalanceDialog, setShowInsufficientBalanceDialog] = useState(false);
  
  const dispatch = useAppDispatch();
  const { provider, account, chainId } = useWeb3React();
  const { inviter, personalSign, userProof } = useAppSelector(
    (state: any) => state.user
  );

  console.log("providerprovider", provider);
  const { t } = useTranslation();

  const useChainId = useMemo(() => {
    if (chainId && CHAIN_CONFIG[chainId]) {
      return chainId;
    }
    return initChainId;
  }, [chainId]);

  const viewNameInfo = useMemo(() => {
    if (location?.state?.searchName) {
      return {
        searchName: location?.state?.searchName,
        searchStatus: location?.state?.searchStatus,
      };
    }
    return null;
  }, [location?.state]);

  const nameStatus: any = useNameStatus(
    name,
    account,
    useChainId,
    userProof,
    viewNameInfo
  );

  console.log("nameStatusnameStatus", nameStatus);

  const useYear = useMemo(() => {
    if (year) {
      return year;
    }
    return 1;
  }, [year]);

  // 减
  const minusFunc = (): void => {
    setYear(Number(year) - 1);
  };

  // 加
  const plusFunc = (): void => {
    if (year && year > 99) {
      setYear(100);
      return;
    }
    setYear(Number(year) + 1);
  };

  const yearChange = (e: any): Boolean | void => {
    const val = e.target.value;
    console.log(val, "yearChange");
    // 非零的正整数
    if (!val) {
      setYear("");
      return;
    }
    const re = /^[1-9]\d*$/;
    if (!e.target.value || !re.test(val)) {
      return false;
    }
    if (e.target.value > 100) {
      setYear(100);
      return;
    }
    setYear(e.target.value);
  };

  const yearBlur = (e: any) => {
    const val = e?.target?.value ? e?.target?.value : 1;
    setFlag(false);
    setYear(val);
  };

  const minusFlag: boolean = year <= 1;

  const { allFee, oneYearFee, balance } = useRentPrice({
    name: name || "",
    years: useYear ? useYear.toString() : "0",
    account,
    chainId: useChainId,
  });

  const {
    callData,
    gasPrice,
    gasLimit,
    contractAddress,
    value: payValue,
  } = useRegisterParams(
    provider,
    account,
    useChainId,
    name || "",
    useYear,
    allFee,
    oneYearFee,
    userProof,
    nameStatus
  );

  const showGas = useMemo(() => {
    if (gasPrice & gasLimit) {
      const result = new BigNumberJs(gasPrice)
        .multipliedBy(gasLimit)
        .shiftedBy(-18)
        .toFixed();
      return result;
    }
    return "";
  }, [gasPrice, gasLimit]);

  console.log("showGas", gasPrice, gasLimit);

  const buyEnable =
    provider &&
    account &&
    useChainId &&
    contractAddress &&
    gasLimit &&
    callData &&
    allFee &&
    userProof?.account &&
    nameStatus;
  console.log("aaaa", {
    provider,
    account,
    useChainId,
    contractAddress,
    gasLimit,
    callData,
    allFee,
    userProofaccount: userProof?.account,
    nameStatus,
  });
  const renderbridge = ()=>{
    if(useChainId !== 534352){
      return null;
    }
    return (
    <div
    dangerouslySetInnerHTML={{__html:t('rQNewmuclIzUDfNFkkCB')
      .replace('<a>', "<a href='https://scroll.io/bridge' style='color:#D1E67D;text-decoration: none' target='_blank' >")}}>
    </div>
  )
  }

  const openOfficialBridge = () => {
    const url = "https://scroll.io/bridge";
    const elem = window.document.createElement('a');
    elem.target = '_blank';
    elem.href = url;
    document.body.appendChild(elem);
    elem.click();
    document.body.removeChild(elem);
    setShowInsufficientBalanceDialog(false);
  };

  const confirmBuy = async () => {
    if (buyEnable) {
      setBuyLoading(true);
      try {
        if (account !== userProof?.account) {
          infoToastTip({
            status: "fail",
            title: t("YRdVoqyWhpdAqYcilT"),
            toastId: `setUpdate${new Date().valueOf()}`,
          });
          setBuyLoading(false);
          return;
        }
        if (!(nameStatus === "available" || nameStatus === "release")) {
          console.log("nameStatusnameStatus", nameStatus);
          const codestatus = statusConstants[nameStatus];
          infoToastTip({
            status: "fail",
            title: codestatus ? t(codestatus) : nameStatus,
            toastId: `setUpdate${new Date().valueOf()}`,
          });
          setBuyLoading(false);
          //throw new Error({"不可以购买"});
          return;
        }
        if (
          balance &&
          payValue &&
          (new BigNumberJs(payValue).isGreaterThan(balance) ||
            new BigNumberJs(balance).isEqualTo("0"))
        ) {
          if(useChainId !== 534352){
            infoToastTip({
              status: "fail",
              title: t("CVaasqzogQmyMJriVJ"),
              toastId: `checkbalance${new Date().valueOf()}`,
              children:renderbridge(),
            });
          }else {
            setShowInsufficientBalanceDialog(true);
          }
          setBuyLoading(false);
          return;
        }
        const tx: any = await registerName(
          provider,
          account,
          chainId,
          contractAddress,
          callData,
          payValue,
          t
        );
        console.log("confirmBuy sendTransaction tx", tx);
        if (!tx?.hash) {
          throw new Error("buy namr not hash");
        }
        const result = await txAwait(provider, tx.hash);
        setBuyLoading(false);
        webClickEvent("Scroll_Ag_Confirm");
        if (result.status === 1) {
          if (inviter && personalSign && personalSign[account]) {
            createInviteRecord({
              signature: personalSign[account],
              hash: result.transactionHash,
              invite_code: inviter,
              invitee: account,
            });
          }
          reSetDomainName(account, useChainId);
          const newDomain = {
            registrant: account,
            owner: account,
            controller: account,
            name: name,
            registered_at: dayjs().format(),
            expired_at: dayjs().add(useYear, "year").format(),
            last_hash: tx.hash,
            created_at: dayjs().format(),
            domainsOwner: true,
            domainsController: true,
            chain_id: useChainId,
          };
          addLocalDomainName(account, newDomain);
          dispatch(updateLocalRegistered({ [account]: true }));
          navigate("/register/success");
        }
      } catch (err: any) {
        console.error("confirmBuy catch", err);
        const showErr =
          err && typeof err === "string" ? err : t("QOriidFKRjTpPdtjkf");
          // let insufficient = false;
          if(t('CVaasqzogQmyMJriVJ') === showErr){
            // insufficient = true;
            if(useChainId === 534352){
              setShowInsufficientBalanceDialog(true);
              setBuyLoading(false);
              return;
            }
          }
        // infoToastTip({
        //   status: "fail",
        //   title: showErr,
        //   toastId: `checkregisterName${new Date().valueOf()}`,
        //   children:insufficient?renderbridge():null,
        // });
        infoToastTip({
          status: "fail",
          title: showErr,
          toastId: `checkregisterName${new Date().valueOf()}`,
        });
        setBuyLoading(false);
      }
    }
  };

  const isOpenBlockMinus = useMemo(() => {
    if (
      userProof?.is_ob_account &&
      name?.length > 4 &&
      nameStatus === "available"
    ) {
      return true;
    }
    return false;
  }, [userProof, name, nameStatus]);

  const showTotal = useMemo(() => {
    let result: any = "--";
    if (allFee) {
      result = allFee;
      if (showGas) {
        result = new BigNumberJs(result).plus(showGas).toFixed();
      }
      if (isOpenBlockMinus) {
        result = new BigNumberJs(result).minus(oneYearFee).toFixed();
      }
    }
    return result;
  }, [showGas, allFee, isOpenBlockMinus]);

  const clickCopy = (copyValue: string) => {
    copyToClipboard(copyValue);
    infoToastTip({
      status: "success",
      title: t("nBMZbjmYxYoLgLGLz"),
      toastId: `setUpdate${new Date().valueOf()}`,
    });
  };
  const backButton =()=>{
    return(
      <div className="register-back-button" onClick={()=>{
        window.history.go(-1);
      }}>
        <GoBackArrowIcon/>
        <span>{t("kyyeXWyPVicVGcWAEwLc")}</span>
      </div>
    )
  }
  return (
    <div className="page-register">
      {backButton()}
      <div className="page-register-content">
        <div className="page-register-title">{t("CSgPMxZIRTiMnrghpBvD")}</div>
        <div className="page-register-domain">
          <div className="page-register-domain-name">{`${name}.scroll`}</div>
          {name ? (
            <div
              className="page-register-domain-copy"
              onClick={() => {
                clickCopy(`${name}.scroll`);
              }}
            >
              <CopyIcon />
            </div>
          ) : null}
        </div>
        <div className="page-register-control">
          <div
            className={classNames("page-register-control-minus-plus", {
              "page-register-control-minus-plus-disabled": minusFlag,
            })}
            onClick={minusFlag ? () => {} : minusFunc}
          >
            <MinusIcon />
          </div>
          {flag ? (
            <div className="page-register-control-input">
              <input
                onChange={yearChange}
                onBlur={yearBlur}
                value={year}
                pattern="[0-9]*"
                min={1}
                type="number"
              />
            </div>
          ) : (
            <div
              className="page-register-control-input"
              onClick={() => setFlag(true)}
            >
              {t("AIvFwbIrTWQVnBUGBw", {
                year: year,
                more: year > 1 ? "s" : "",
              })}
            </div>
          )}
          <div
            className={classNames("page-register-control-minus-plus", {
              "page-register-control-minus-plus-disabled": flag,
            })}
            onClick={flag ? () => {} : plusFunc}
          >
            <PlusIcon />
          </div>
        </div>
        <div className="page-register-details">
          <div className="page-register-details-item">
            <div className="page-register-details-item-label">
              {t("IvUjvBTpxYhAbkkqMN", { year: useYear })}
            </div>
            <div className="page-register-details-item-value">
              {allFee} ETH {getUsdPrice(allFee)}
            </div>
          </div>
          <div className="page-register-details-item">
            <div className="page-register-details-item-label">
              {t("cGQUDgYgOpFIJaVpLI")}
            </div>
            {nameStatus && showGas ? (
              <div className="page-register-details-item-value">
                {showGas} ETH {getUsdPrice(showGas)}
              </div>
            ) : (
              <div className="btn-loading-icon" />
            )}
          </div>
          {isOpenBlockMinus ? (
            <div className="page-register-details-item">
              <div className="page-register-details-item-ob-label">
                {t("wyoamYDxPPLLVSewA")}
              </div>
              <div className="page-register-details-item-ob-value">
                {oneYearFee} ETH {getUsdPrice(oneYearFee)}
              </div>
            </div>
          ) : null}
          <div className="page-register-details-item">
            <div className="page-register-details-item-label">
              {t("YfclQcbcgrggHxaGV")}
            </div>
            <div className="page-register-details-item-amount">
              {showTotal} ETH {getUsdPrice(showTotal)}
            </div>
          </div>
        </div>
        {account ? (
          <div
            className={classNames("page-register-submit", {
              "btn-disable": !buyEnable,
              "btn-loading": buyLoading,
            })}
            onClick={confirmBuy}
          >
            {buyLoading ? <div className="btn-loading-icon" /> : null}
            {buyLoading ? t("hauYoTIzrwJLYjdD") : t("hgvNASEYHzmiBaqXlx")}
          </div>
        ) : (
          <div
            className={classNames("page-register-submit")}
            onClick={() => {
              dispatch(updateShowLoginDialog({ showLoginDialog: true }));
            }}
          >
            {t("YkqSDwKovDFmDjUTnt")}
          </div>
        )}
        {
           showInsufficientBalanceDialog && <div className="insufficient-balance-modal">
            <div className="ibm-content">
              <CloseIcon className="ibm-content-header" onClick={()=>{
                setShowInsufficientBalanceDialog(false);
              }}></CloseIcon>
              {/* <DangerousIcon className="ibm-content-icon"></DangerousIcon> */}
              <div className="ibm-content-text">{ t("CVaasqzogQmyMJriVJJ")}</div>
              {/* <div className="ibm-content-bridge"> {renderbridge()}</div> */}
              <div className="ibm-content-bridge" onClick={openOfficialBridge}> {t('rQNewmuclIzUDfNFkkCBB')}</div>
            </div>
           </div>
        }
      </div>
    </div>
  );
};

export default NameRegister;
